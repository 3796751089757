import ImageHandler from "./ImageHandler";
import { footerData } from "../../utils/data/footerData";
import { useTranslation } from "react-i18next";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { companyData } from "../../utils/data/companyData";
import { useNavigate } from "react-router-dom";
import { scrollTo } from "../functions/scrollTo";
import i18n, { resources } from "../../utils/localization/i18n";


export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-100 bg-grey mt-auto">
      <div className="container mt-2 mt-md-5">
        <div className="row align-items-center align-items-md-start gy-3">
          <div className="col-md-3 px-md-0 d-flex flex-column align-items-center align-items-md-start">
            <div className="d-flex flex-column mb-3 align-items-center align-items-md-start">
              <ImageHandler
                src="./images/optika-leka-logo2-removebg-preview.png"
                className="object-fit-cover w-25 d-md-none my-3"
              />

              <p className="mb-1 small"> {companyData?.street} </p>
              <p className="mb-1 small"> {companyData?.city}, {companyData?.zipCode} </p>
              <p className="mb-1 small"> {companyData?.phone[0]} </p>
              <p className="mb-1 small"> {companyData?.phone[1]} </p>
            </div>
            <div className="d-none d-md-block">
              <p className="mb-1 small"> {t("general.followUs")}: </p>
              <div>
                <a href={companyData?.instagramLink} target="_blank" rel="noreferrer">
                  <FaInstagram className="wh-25px me-2 cursor-pointer text-primary" />
                </a>
                <a href={companyData?.fbLink} target="_blank" rel="noreferrer">
                  <FaFacebook className="wh-25px cursor-pointer text-primary" />
                </a>
              </div>
            </div>
          </div>

          {footerData?.map((footerItem) => {
            const isCategories = footerItem?.id === 4;
            return (
              <div className={`col-md-${isCategories ? 3 : 2} d-flex d-md-block flex-column align-items-center`} key={footerItem?.id}>
                <h6 className="text-uppercase hover-underline-animation fw-bold"> {t(footerItem?.title)} </h6>
                <div className={`${isCategories ? 'align-items-center flex-wrap row-cols-1 row-cols-md-2' : 'flex-column'} d-flex `}>
                  {footerItem?.items?.map((listItem) => {
                    return (
                      <span className="cursor-pointer mb-2 text-center text-md-start" onClick={() => {
                        navigate(listItem?.navigateTo);
                        scrollTo();
                      }} key={listItem?.id}>
                        {t(listItem?.name)}
                      </span>
                    )
                  })}
                </div>
              </div>
            )
          })}
          <div className="col-12 d-flex align-items-center flex-column d-md-none">
            <p className="mb-1"> Follow us on: </p>
            <div>
              <a href={companyData?.instagramLink} target="_blank" rel="noreferrer">
                <FaInstagram className="wh-25px me-2 cursor-pointer text-primary" />
              </a>
              <a href={companyData?.fbLink} target="_blank" rel="noreferrer">
                <FaFacebook className="wh-25px cursor-pointer text-primary" />
              </a>
            </div>
          </div>
        </div>

        <div className="row mt-4 mt-md-5 border-top border-primary py-3">
          <div className="col-12 px-0 d-flex flex-column flex-md-row justify-content-between align-items-center">
            <span>
              © {new Date()?.getFullYear()} | {t("messages.allRightsReserved")}
            </span>

            <div className="my-2 my-md-0">
              <span>
                Developed by
              </span>
              <a href="https://mattemattika.com/" target={'_blank'} rel="noreferrer"
                className={'text-primary text-decoration-none cursor-pointer ms-1'}>
                Tomić
              </a>
              <span className={'mx-1'}>
                &
              </span>
              <a href="https://lazarevic.live/" target={'_blank'} rel="noreferrer"
                className={'text-primary text-decoration-none cursor-pointer'}>
                Lazarević
              </a>
            </div>

            <select
              className="form-select bg-transparent w-120px text-muted"
              value={i18n.language}
              onChange={(e) => {
                i18n.changeLanguage(e?.target?.value);
                localStorage.setItem("language", e?.target?.value);
              }}
            >
              {Object.keys(resources)?.map((language) => {
                return (
                  <option value={language} key={language}>
                    {t(`language.${language}`)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div >
  );
}
