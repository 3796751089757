import React from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FiPhoneCall } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { scrollTo } from '../../../../shared/functions/scrollTo';
import { companyData } from '../../../../utils/data/companyData';


export default function ContactHome() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="col-12 contact-home-bg border border-secondary p-5 rounded my-5">
            <div className="row align-items-center">
                <div className="col-lg-4 d-flex align-items-center justify-content-center">
                    <FiPhoneCall className='wh-50px text-primary' />
                    <div className='ms-4'>
                        <p className='mb-0 fs-20px'> {t("messages.forMoreInformation")}</p>
                        <h5 className='fw-bold text-primary text-md-center'> {companyData?.phone[0]} </h5>
                        <h5 className='fw-bold text-primary text-md-center'> {companyData?.phone[1]} </h5>
                    </div>
                </div>

                <div className="col-lg-5 px-lg-5 my-5 my-lg-0">
                    <p className="mb-0 fs-25px text-primary fw-bold text-center"> {t("messages.makeReservation")} </p>
                </div>

                <div className="col-lg-3 d-flex align-items-center justify-content-center">
                    <Button className='rounded-pill bg-primary text-white text-uppercase p-3'
                        onClick={() => {
                            navigate("/contact");
                            scrollTo();
                        }}>{t("footer.contactUs")}</Button>
                </div>
            </div>
        </div>
    )
}
