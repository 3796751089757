import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-h-50vh">
      <h1 className="display-1 fw-bold text-uppercase"> oops! </h1>
      <h3> {t("general.pageNotFound")}</h3>
      <small> {t("messages.notFound")} </small>

      <Button
        variant="primary"
        className="rounded-0 w-200px mt-5 text-uppercase"
        onClick={() => navigate("/")}
      >
        {t("buttons.goBack")}
      </Button>
    </div>
  );
};

export default NotFound;
