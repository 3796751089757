import React from 'react'
import { IFaq } from '../../../../shared/interfaces/IFaq'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FieldErrors, UseFormRegister } from 'react-hook-form'

interface IProps {
    currentFaq: IFaq | null,
    register: UseFormRegister<IFaq>,
    errors: FieldErrors<IFaq>,
}

export default function FaqForm({ currentFaq, register, errors }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="px-2">
            {currentFaq?.id &&
                <Form.Control
                    type="text"
                    value={"ID: " + currentFaq?.id}
                    className={'rounded-0 p-3 mb-3'}
                    disabled
                />
            }
            <Form.Control
                placeholder={t("table.question")}
                className={`rounded-0 p-3 ${errors.name ? 'border-danger mb-0' : 'mb-3'}`}
                {...register("name")}
            />
            {errors?.name && <div className='text-danger small mb-3'> {t(`${errors.name.message}`)} </div>}

            <Form.Control
                as="textarea"
                placeholder={t("table.answer")}
                className={`rounded-0 p-3 overflow-auto h-200px ${errors.description ? 'border-danger mb-0' : 'mb-3'}`}
                {...register("description")}
            />
            {errors?.description && <div className='text-danger small mb-3'> {t(`${errors.description.message}`)} </div>}

            <div className='d-flex justify-content-end mb-5'>
                <Button
                    onClick={() => navigate(-1)}
                    className={'text-uppercase bg-secondary text-white rounded-0 border-0'}>
                    {t("buttons.cancel")}
                </Button>
                <Button variant="primary"
                    type="submit"
                    className={'ms-2 text-uppercase rounded-0 border-0'}>
                    {currentFaq?.id ? t("buttons.update") : t("buttons.create")}
                </Button>
            </div>
        </div>
    )
}
