import { IBrand } from "../../../shared/interfaces/IBrand";
import { IPagination } from "../../../shared/interfaces/IPagination";
import { requests } from "../../../utils/api/api.service";
import { ADMIN_BRAND } from "../../../utils/api/endpoints";


export const BrandService = {
    getAllBrands: (pagination: IPagination): Promise<{ data: IBrand[], count: number, perPage: number }> =>
        requests.get(ADMIN_BRAND, pagination),

    getBrandById: (brandId: number) =>
        requests.get(`${ADMIN_BRAND}/${brandId}`),

    updateBrand: (payload: IBrand, brandId: number) =>
        requests.put(`${ADMIN_BRAND}/${brandId}`, payload),

    deleteBrand: (brandId: number) =>
        requests.delete(`${ADMIN_BRAND}/${brandId}`),

    createBrand: (payload: IBrand) =>
        requests.post(ADMIN_BRAND, payload)
}