import { yupResolver } from "@hookform/resolvers/yup";
import React from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleError } from '../../../../shared/functions/handleError';
import { IFaq } from '../../../../shared/interfaces/IFaq';
import { FAQService } from '../service';
import { schemaFaqInput } from './CreateFaq';
import FaqForm from './FaqForm';

export default function UpdateFaq() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, getValues, formState: { errors } } = useForm<IFaq>({
        resolver: yupResolver(schemaFaqInput), defaultValues: async () => await FAQService.getFaqById(Number(id))
    })

    const onSubmitFaq = (formState: IFaq) => {
        FAQService.updateFaq(formState, Number(id)).then(response => {
            if (response) {
                toast.success(t("general.successfullyUpdated"));
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmitFaq)}
        >
            <FaqForm currentFaq={getValues()} register={register} errors={errors} />
        </Form>
    )
}
