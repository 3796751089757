import React, { useEffect, useState } from 'react'
import { IBlog } from '../../../../shared/interfaces/IBlog';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { handleError } from '../../../../shared/functions/handleError';
import BlogCard from '../../blog/components/BlogCard';

//swiper
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { BlogService } from '../../../admin/blog/service';
import { useTranslation } from 'react-i18next';

export default function Blogs() {
    const [blogs, setBlogs] = useState<IBlog[]>([]);
    const { t } = useTranslation();
    const { pagination } = usePagination(
        {
            perPage: 0
        }
    )

    useEffect(() => {
        BlogService.getAllBlogPosts(pagination).then(response => {
            setBlogs(response?.data);
        }).catch(err => handleError(err));
    }, [pagination, setBlogs]);

    return (
        <div className="col-12 my-5">
            <h2 className="fw-bold text-center mb-3 mb-lg-4"> {t("general.blogs")} </h2>

            <Swiper
                spaceBetween={25}
                slidesPerView={4}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 1,
                    },
                    639: {
                        slidesPerView: 2,
                    },
                    865: {
                        slidesPerView: 3,
                    },
                }}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
            >
                {blogs?.map((blog) => {
                    return (
                        <SwiperSlide key={blog?.id}>
                            <BlogCard blog={blog} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    )
}
