export const brandsData = [
  {
    id: 1,
    name: "Gucci",
    path: "https://1000logos.net/wp-content/uploads/2017/01/Gucci-Logo.png",
  },
  {
    id: 2,
    name: "YSL",
    path: "https://logowik.com/content/uploads/images/529_ysl.jpg",
  },
  {
    id: 3,
    name: "Marc Jacobs",
    path: "https://1000logos.net/wp-content/uploads/2021/05/Marc-Jacobs-logo.png",
  },
  {
    id: 4,
    name: "Alexander McQueen",
    path: "https://1000logos.net/wp-content/uploads/2018/12/Alexander-McQueen-Logo-1992.png",
  },
  {
    id: 5,
    name: "Just Cavalli",
    path: "https://1000logos.net/wp-content/uploads/2020/03/Just-Cavalli-logo.jpg",
  },
  {
    id: 6,
    name: "Furla",
    path: "https://1000logos.net/wp-content/uploads/2022/07/Furla-logo.png",
  },
  {
    id: 7,
    name: "Dsquared2",
    path: "https://1000logos.net/wp-content/uploads/2022/07/Dsquared2-logo.png",
  },
  {
    id: 8,
    name: "Moschino",
    path: "https://1000logos.net/wp-content/uploads/2021/05/Moschino-logo.png",
  },
  {
    id: 9,
    name: "Jimmy Choo",
    path: "https://1000logos.net/wp-content/uploads/2022/07/Jimmy-Choo-logo.png",
  },
  {
    id: 10,
    name: "For Art Sake",
    path: "https://www.fasforartssake.com/cdn/shop/files/FAS_london.png?v=1707213464",
  },
];
