import React from 'react'
import { Form } from 'react-bootstrap'
import { multipartFormData } from '../../../../utils/api/api.service'
import { ADMIN_BLOG } from '../../../../utils/api/endpoints'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { handleError } from '../../../../shared/functions/handleError'
import { BlogService } from '../service'
import BlogForm from './BlogForm'
import { Resolver, useForm } from 'react-hook-form'
import { IBlogInput, } from './CreateBlogPost'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';

const schemaBlogUpdate = yup.object().shape({
    title: yup.string().required("error.error-required"),
    shortDescription: yup.string().required("error.error-required"),
    description: yup.string().required("error.error-required"),
})

export default function ViewBlogPost() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, getValues, handleSubmit, formState: { errors }, watch, control } = useForm<IBlogInput>({
        resolver: yupResolver(schemaBlogUpdate) as unknown as Resolver<IBlogInput, any>,
        defaultValues: async () => await BlogService.getBlogById(Number(id))
    })

    const handleSubmitBlog = (formData: IBlogInput) => {
        !!formData.id && multipartFormData(formData?.file?.[0], `${ADMIN_BLOG}/${formData.id}`,
            {
                title: formData?.title,
                description: formData?.description,
                shortDescription: formData?.shortDescription
            }, 'put', 'file').then(response => {
                if (response) {
                    toast.success(t("general.successfullyUpdated"));
                    navigate(-1);
                }
            }).catch(err => handleError(err));
    }

    return (
        <Form
            onSubmit={handleSubmit(handleSubmitBlog)}
        >
            <BlogForm
                control={control}
                currentBlog={getValues()}
                errors={errors}
                register={register}
                localFile={watch('file')?.[0]}
            />
        </Form>
    )
}
