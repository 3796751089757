import React from 'react'
import ImageHandler from '../../../../shared/components/ImageHandler'
import LabelBox from './LabelBox'

export default function HomeCollections() {
    return (
        <div className="row my-4">
            <div className="col-md-6 position-relative ps-md-0">
                <ImageHandler src="./images/girl-glasses-1.webp" className='w-100 object-fit-cover' />
                <LabelBox boxTitle='woman tops' className="first-w" />

                <ImageHandler src="./images/girl-glasses-2.webp" className='w-100 object-fit-cover my-3 ' />
                <LabelBox boxTitle='new arrivals' className="second-w" />

            </div>

            <div className="col-md-6 position-relative pe-md-0">
                <ImageHandler src="./images/man-glasses-1.webp" className='w-100 object-fit-cover' />
                <LabelBox boxTitle='for men' className="first-m" />

                <ImageHandler src="./images/man-glasses-2.webp" className='w-100 object-fit-cover my-3' />
                <LabelBox boxTitle='new collection' className="second-m" />

            </div>
        </div>
    )
}
