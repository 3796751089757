import React from 'react'
import { Form } from 'react-bootstrap';
import CategoryForm from './CategoryForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICategory } from '../../../../shared/interfaces/ICategory';
import { handleError } from '../../../../shared/functions/handleError';
import { toast } from 'react-toastify';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_CATEGORIES } from '../../../../utils/api/endpoints';
import { CategoryService } from '../service';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { schemaCategory } from './CreateCategory';

export default function UpdateCategory() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, getValues, watch } = useForm<ICategory>({
        resolver: yupResolver(schemaCategory) as unknown as Resolver<ICategory, any>,
        defaultValues: async () => await CategoryService.getCategoryById(Number(id))
    })

    const onSubmitCategory = (formData: ICategory) => {
        multipartFormData(formData?.file?.[0], `${ADMIN_CATEGORIES}/${formData?.id}`, formData as ICategory, 'put', 'file').then((response) => {
            if (response) {
                toast.success(t('general.successfullyUpdated'));
                navigate(-1);
            }
        }).catch(err => handleError(err))
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmitCategory)}
        >
            <CategoryForm
                currentCategory={getValues()}
                errors={errors}
                register={register}
                localFile={watch('file')?.[0]}
            />
        </Form>
    )
}
