import React, { useEffect, useState } from "react";
import { Pagination as PaginationComponent } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
  totalItems?: number;
  totalPages: number;
  currentPage: number;
  onPerPageClick?: (newPerPage: number) => void;
  onPageClick: (page: number) => void;
  position?: "start" | "end" | "center";
}

const Pagination = ({
  totalPages,
  currentPage,
  onPageClick,
  position = "center",
  totalItems,
  onPerPageClick,
}: IProps) => {
  const [pageItem, setPageItem] = useState<number[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    setPageItem([]);
    if (currentPage !== 1 && currentPage !== totalPages) {
      setPageItem([currentPage - 1, currentPage, currentPage + 1]);
    }

    if (currentPage === 1) {
      for (let i = 0; i < totalPages; i++) {
        if (i < 3) setPageItem((prev) => [...prev, i + 1]);
      }
    }

    if (currentPage === totalPages) {
      for (let i = totalPages; i >= 0; i--) {
        if (i > totalPages - 3 && i !== 0) setPageItem((prev) => [i, ...prev]);
      }
    }
  }, [totalPages, currentPage]);

  return (
    <>
      <div className={`d-flex align-items-center justify-content-between mb-3`}>
        {!!totalItems && (
          <h6 className={"text-uppercase"}>
            {t("general.totalCount")}: {totalItems}
          </h6>
        )}
        {totalPages > 1 && (
          <PaginationComponent className={`mb-0`}>
            {currentPage !== 1 && (
              <>
                <PaginationComponent.First onClick={() => onPageClick(1)} />
                <PaginationComponent.Prev
                  onClick={() => onPageClick(currentPage - 1)}
                />
              </>
            )}

            {pageItem?.map((el) => {
              return (
                <PaginationComponent.Item
                  key={el}
                  active={currentPage === el}
                  onClick={() => onPageClick(el)}
                >
                  {el}
                </PaginationComponent.Item>
              );
            })}

            {currentPage !== totalPages && (
              <>
                <PaginationComponent.Next
                  onClick={() => onPageClick(currentPage + 1)}
                />
                <PaginationComponent.Last
                  onClick={() => onPageClick(totalPages)}
                />
              </>
            )}
          </PaginationComponent>
        )}
        {!!onPerPageClick && !!totalItems && (
          <div className={"d-flex align-items-center"}>
            <h6 className={"text-uppercase me-1 mb-0"}>Per page:</h6>
            <select
              name="perPage"
              className={"form-select w-fit"}
              onChange={(event) => onPerPageClick(+event.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        )}
      </div>
    </>
  );
};

export default Pagination;
