import React from 'react'
import { Form } from 'react-bootstrap';
import BrandForm from './BrandForm';
import { IBrand } from '../../../../shared/interfaces/IBrand';
import { BrandService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const schemaBrand = yup.object().shape({
    name: yup.string().required("error.error-required"),
})

export default function CreateBrand() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, getValues, formState: { errors } } = useForm<IBrand>({ resolver: yupResolver(schemaBrand) })

    const onSubmitBrand = (formState: IBrand) => {
        BrandService.createBrand(formState as IBrand).then(() => {
            toast.success(t("general.successfullyCreated"));
            navigate(-1);
        }).catch(err => handleError(err));
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmitBrand)}
        >
            <BrandForm currentBrand={getValues()} register={register} errors={errors} />
        </Form>
    )
}
