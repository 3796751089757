import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { formatPrice } from '../../../../shared/functions/formatPrice'
import { useTranslation } from 'react-i18next'
import { IDashboardItem } from '../../../../shared/interfaces/IDashboard'

interface IProps {
    dashboardItem: IDashboardItem
}

export default function DashboardCard({ dashboardItem }: IProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Card className="h-100">
            <Card.Body>
                <Card.Title className="text-uppercase text-muted fw-bold"> {dashboardItem?.title} </Card.Title>
                <div>
                    <h4 className="my-3 fw-bold">
                        {dashboardItem?.title === t('general.earnings') ? formatPrice(dashboardItem?.number) : dashboardItem.number}
                    </h4>
                    <div className={`d-flex justify-content-${!!dashboardItem?.navigateTo ? 'between' : 'end'}`}>
                        {!!dashboardItem?.navigateTo?.length &&
                            <span className="cursor-pointer hover-underline-animation"
                                onClick={() => navigate(dashboardItem?.navigateTo!)}
                            > {t('general.viewAll')} </span>}
                        {dashboardItem?.icon}
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}
