import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CategoryService } from '../../categories/service';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { handleError } from '../../../../shared/functions/handleError';
import Fancybox from '../../../../shared/components/Fancybox';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { MdDelete } from 'react-icons/md';
import ConfirmationModal from '../../../../shared/components/ConfirmationModal';
import { ProductService } from '../service';
import { toast } from 'react-toastify';
import { IPhoto } from '../../../../shared/interfaces/IPhoto';
import { ICategory } from '../../../../shared/interfaces/ICategory';
import { IProduct } from '../../../../shared/interfaces/IProduct';
import { BrandService } from '../../brands/service';
import { IBrand } from '../../../../shared/interfaces/IBrand';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ICreateProduct } from './CreateProduct';
import FileUpload from '../../../../shared/components/FileUpload';

interface IProps {
    currentProduct: IProduct | null | ICreateProduct;
    files?: FileList | null;
    watch: UseFormWatch<ICreateProduct>
    register: UseFormRegister<ICreateProduct>,
    setValue?: UseFormSetValue<ICreateProduct>
    errors: FieldErrors<ICreateProduct>
}

export default function ProductForm({ currentProduct, watch, errors, register, setValue }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [brands, setBrands] = useState<IBrand[]>([]);

    const { pagination } = usePagination({
        perPage: 0,
    });

    const [selectedImage, setSelectedImage] = useState<IPhoto | null>(null);
    const [isDeleteVisible, setIsDeleteVisible] = useState(false);

    const handleCloseDeleteModal = () => {
        setIsDeleteVisible(false);
        setSelectedImage(null);
    }

    const handleDeleteImage = () => {
        selectedImage?.id && ProductService.deleteProductImg(+selectedImage?.id).then(response => {
            if (response) {
                setValue && setValue('productPhotos', currentProduct?.productPhotos?.filter(photo => photo.id !== selectedImage?.id))
                toast.success(t('general.successfullyDeleted'));
                setSelectedImage(null);
                setIsDeleteVisible(false);
            }
        }).catch(err => handleError(err));
    }

    useEffect(() => {
        CategoryService.getAllCategories(pagination).then(response =>
            setCategories(response?.data)
        ).catch(err => handleError(err));
    }, [pagination]);

    useEffect(() => {
        BrandService.getAllBrands(pagination).then(response =>
            setBrands(response?.data)
        ).catch(err => handleError(err));
    }, [pagination]);

    return (
        <div className="row">
            <div className="col-md-6">
                {currentProduct?.id && <>
                    <Form.Label className="mb-0 h6">{t('table.id')}</Form.Label>
                    <input
                        type="text"
                        className={'form-control mb-3 rounded-0 p-3'}
                        disabled
                        {...register('id')}
                    /> </>}

                <Form.Label className="mb-0 h6">{t('table.name')}</Form.Label>
                <input
                    type="text"
                    {...register('name')}
                    className={`form-control ${errors?.name ? 'mb-0 border-danger' : 'mb-3'} rounded-0 p-3`}
                />
                {errors?.name && <div className='text-danger small mb-3'> {t(`${errors.name.message}`)}</div>}

                <Form.Label className="mb-0 h6">{t('table.category')}</Form.Label>
                <select
                    {...register('categoryId')}
                    className={`form-select border rounded-0 p-3 ${errors.categoryId ? 'mb-0 border-danger' : 'mb-3'}`}
                >
                    <option hidden> {t('general.selectCategory')} </option>
                    {categories?.map((category) => {
                        return <option key={category?.id} value={category?.id}> {category?.name} </option>
                    })}
                </select>
                {errors?.categoryId && <div className='text-danger small mb-3'> {t(`${errors.categoryId.message}`)}</div>}

                <Form.Label className="mb-0 h6">{t('table.brand')}</Form.Label>
                <select
                    {...register('brandProductId')}
                    className={`form-select border rounded-0 p-3 ${errors.brandProductId ? 'mb-0 border-danger' : 'mb-3'}`}
                >
                    <option hidden> {t('general.selectBrand')} </option>
                    {brands?.map((brand) => {
                        return <option key={brand?.id} value={brand?.id}> {brand?.name} </option>
                    })}
                </select>
                {errors?.brandProductId && <div className='text-danger small mb-3 '> {t(`${errors.brandProductId.message}`)}</div>}

                <Form.Label className="mb-0 h6">{t('table.price')}</Form.Label>
                <input type="text"
                    {...register('price')}
                    className={`form-control ${errors.price ? 'mb-0 border-danger' : 'mb-3'} rounded-0 p-3`}
                />
                {errors?.price && <div className='text-danger small mb-3'> {t(`${errors.price.message}`)}</div>}


                <Form.Label className="mb-0 h6">{t('general.color')}</Form.Label>
                <input
                    type="text"
                    {...register('color')}
                    className={'form-control mb-3 rounded-0 p-3'}
                />

                <Form.Label className="mb-0 h6">{t('table.sku')}</Form.Label>
                <input
                    type="text"
                    {...register('sku')}
                    className={`form-control ${errors.sku ? 'mb-0 border-danger' : 'mb-3'} rounded-0 p-3`}
                />
                {errors?.sku && <div className='text-danger small mb-3'> {t(`${errors.sku.message}`)}</div>}


                <Form.Label className="mb-0 h6">{t('table.quantity')}</Form.Label>
                <input type="text"
                    {...register('quantity')}
                    className={`form-control ${errors.quantity ? 'mb-0 border-danger' : 'mb-3'} rounded-0 p-3`}
                />
                {errors?.quantity && <div className='text-danger small mb-3'> {t(`${errors.quantity.message}`)}</div>}


                <Form.Label className="mb-0 h6">{t('table.discount')}</Form.Label>
                <input type="number"
                    {...register('discount')}
                    className={'form-control mb-3 rounded-0 p-3'}
                    placeholder={'0-100%'}
                />

                <Form.Label className="mb-0 h6">{t('table.description')}</Form.Label>
                <textarea
                    {...register('description')}
                    className={`form-control ${errors.description ? 'mb-0 border-danger' : 'mb-3'} rounded-0 p-3 h-200px`}
                />
                {errors?.description && <div className='text-danger small mb-3'> {t(`${errors.description.message}`)}</div>}


                <Form.Label className="mb-0 h6">{t('table.additionalDescription')}</Form.Label>
                <textarea
                    {...register('additionalDescription')}
                    className={'form-control mb-3 rounded-0 p-3 h-200px'}
                />
            </div>
            <div className="col-md-6">
                <div>
                    <Form.Label className="mb-0 h6">{t('table.photos')}</Form.Label>
                    <FileUpload register={register}
                        errors={errors}
                        isMultiple={true}
                        //@ts-ignore
                        files={watch('files') || []}
                        isRequired={!currentProduct?.id}
                        formatType="image/*" />
                </div>

                {!!watch('productPhotos')?.length &&
                    <div>
                        <Fancybox
                            options={{
                                Carousel: {
                                    infinite: false,
                                },
                            }}>
                            <div className="row">
                                {watch('productPhotos')?.map(photo => {
                                    return (
                                        <div className="col-md-4 mb-3 ps-0 position-relative" key={photo.id}>
                                            <a data-fancybox="gallery" href={photo?.path}>
                                                {(watch('productPhotos') as IPhoto[])?.length > 1 && <div
                                                    onClick={() => {
                                                        setSelectedImage(photo);
                                                        setIsDeleteVisible(true);
                                                    }}
                                                    className="top-right-icon bg-white hover-effect card-border-radius position-absolute"
                                                    style={{
                                                        top: 10,
                                                        right: 20
                                                    }}>
                                                    <MdDelete className="text-danger wh-25px cursor-pointer" />
                                                </div>}

                                                <ImageHandler src={photo?.path}
                                                    className={'product-order-img cursor-pointer border'} />
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </Fancybox>
                    </div>}

            </div>

            <div className="d-flex justify-content-end mb-5">
                <Button variant="secondary"
                    onClick={() => navigate(-1)}
                    className={'text-uppercase bg-secondary text-white rounded-0'}>
                    {t('buttons.cancel')}
                </Button>
                <Button variant="primary"
                    type="submit"
                    className={'ms-2 text-uppercase rounded-0'}>
                    {currentProduct?.id ? t('buttons.update') : t('buttons.create')}
                </Button>

            </div>

            <ConfirmationModal
                handleConfirm={handleDeleteImage}
                handleClose={handleCloseDeleteModal} show={isDeleteVisible} title={`image ID: ${selectedImage?.id}`} />
        </div>
    )
}
