import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IOptions {
    name: string;
    value: number | string;
}

export interface IProps
    extends React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    defaultLabel?: string;
    options: IOptions[];
}

const SelectDropdown: React.FC<IProps> = ({
    options,
    defaultLabel = 'defaultLabel',
    ...props
}) => {

    const { t } = useTranslation();

    return (
        <select className={'form-select-sm form-select border-0 p-0 text-uppercase'} {...props}>
            <option value={''}>{defaultLabel === 'defaultLabel' ? t(`general.${defaultLabel}`) : defaultLabel}</option>

            {options?.map(option => {
                return (
                    <option value={option?.value} key={option?.name} >
                        {option?.name}
                    </option>
                )
            })}
        </select>
    );
};

export default SelectDropdown;
