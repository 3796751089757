import { IProduct } from "../interfaces/IProduct";
import { countNewPrice } from "./countNewPrice";

export const getTotalPrice = (cart: IProduct[]) => {
  const prices = cart?.map((product) => {
    if (product?.discount)
      return (
        Number(
          countNewPrice(product?.price as number, product?.discount as number)
        ) * Number(product?.cartQuantity)
      );
    return (product?.price as number) * Number(product?.cartQuantity);
  });

  return prices?.reduce((prev, curr) => prev + curr, 0);
};
