import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import AdminDashboard from '../../app/admin/dashboard';
import UserOrders from './components/UserOrders';
import Wishlist from '../../app/public/wishlist';
import MyAccountAddress from './components/MyAccountAddress';
import { myAccountData } from '../../utils/data/myAccountData';
import { ERole } from '../enums/ERole';
import CompanyDashboard from './components/CompanyDashboard';
import authSlice from '../../store/slices/auth.slice';
import wishlistSlice from '../../store/slices/wishlist.slice';
import cartSlice from '../../store/slices/cart.slice';
import AccountDetails from './components/account-details/AccountDetails';

export default function MyAccount() {
    const { t } = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams();
    const [activePageId, setActivePageId] = useState<number>(+(searchParams.get("route") || 1));
    const { userData } = useSelector((state: RootState) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const componentsMap = {
        1: <AdminDashboard />,
        2: <UserOrders />,
        3: <CompanyDashboard />,
        4: <MyAccountAddress />,
        5: <AccountDetails />,
        6: <Wishlist isMyAccount={true} />,
    };

    const getCorrectTabComponent = (tabId: keyof typeof componentsMap) => {
        return componentsMap[tabId] || `No component for ID: ${tabId}`;
    };

    return (
        <>
            <div className="row mt-md-5">
                <div className="col-12">
                    <h2 className="text-uppercase fw-bold">
                        {t(`${myAccountData?.filter((data) =>
                            data?.roles?.includes(userData?.role as ERole))?.find((route) => route.id === activePageId)?.title}`)}
                    </h2>
                </div>
            </div>

            <div className="row my-3 my-md-5">
                <div className="col-md-2">
                    <ul className="list-unstyled d-flex justify-content-center flex-wrap d-md-block">
                        {myAccountData
                            ?.filter((data) => data?.roles?.includes(userData?.role as ERole))
                            ?.map((data) => {
                                const isActive = activePageId === data?.id;
                                return (
                                    <li
                                        key={data.id}
                                        className={`d-flex w-fit hover-underline-animation text-uppercase text-${isActive ? "primary" : "dark"
                                            } mb-3 fw-semibold cursor-pointer mx-3 mx-md-0`}
                                        onClick={() => {
                                            setActivePageId(data?.id);
                                            setSearchParams((params) => {
                                                params.set("route", String(data?.id));
                                                return params;
                                            });
                                            if (data?.id === 7) {
                                                localStorage.clear();
                                                navigate("/login");
                                                dispatch(authSlice.actions.setLogoutState());
                                                dispatch(wishlistSlice.actions.setWishlist([]));
                                                dispatch(cartSlice.actions.setCart([]));
                                            }
                                        }}
                                    >
                                        {t(data.title)}
                                    </li>
                                );
                            })}
                    </ul>
                </div>

                <div className="col-md-10">
                    {getCorrectTabComponent(activePageId as keyof typeof componentsMap)}
                </div>
            </div>
        </>
    )
}
