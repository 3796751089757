import React from 'react'
import { FaStar } from "react-icons/fa";
import { IImpression } from '../../../../../../shared/interfaces/IImpression';

interface IProps {
    impression: IImpression;
}
export default function ImpressionCard({ impression }: IProps) {
    return (
        <div className="card border-0 p-3">
            <div className="card-body h-200px position-relative box-shadow-impressions">
                <div className="d-flex gap-1 mb-3">
                    {[...Array(5)].map((e, i) => <FaStar key={i} style={{ color: "#FFD700" }} />)}
                </div>
                <p className="card-text">
                    {impression?.comment}
                </p>
                <div className="d-flex justify-content-end">
                    <p className="position-absolute bottom-0 mb-0 p-3"> {impression?.username} </p>
                </div>
            </div>
        </div>

    )
}
