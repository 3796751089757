import React from 'react'
import { companyData } from '../../../utils/data/companyData'
import ImageHandler from '../../../shared/components/ImageHandler'

export default function Policy() {
    return (
        <div className='row my-3 '>
            <div className="col-12 px-md-0">
                <h3 className='fw-bold border-bottom'> Politika privatnosti</h3>
                <p className="mt-4">
                    Politika privatnosti opisuje kako prikupljamo, koristimo i štitimo informacije koje dobijamo od korisnika naše veb stranice.
                    Koristeći naš sajt i usluge koje pruža, slažete se sa prikupljanjem i korišćenjem informacija u skladu sa ovom politikom.
                </p>

                <div className='border-bottom'>
                    <h5 className='fw-bold'> 1. Prikupljanje informacija </h5>
                    <p>
                        Prikupljamo informacije kada:
                        <ul>
                            <li> Registrujete nalog na našem sajtu </li>
                            <li>  Naručujete proizvode </li>
                            <li>  Popunjavate formulare </li>
                            <li>  Komunicirate s nama putem e-pošte ili drugih kanala </li>
                        </ul>
                    </p>
                    <p>
                        Informacije koje možemo prikupiti uključuju:
                        <ul>
                            <li>Ime i prezime</li>
                            <li>Adresa e-pošte</li>
                            <li>Adresa za dostavu </li>
                            <li>Broj telefona</li>
                        </ul>
                    </p>
                </div>

                <div className='border-bottom mt-3'>
                    <h5 className='fw-bold'> 2. Korišćenje informacija </h5>
                    <p>
                        Koristimo prikupljene informacije za:
                        <ul>
                            <li>Obradu vaših narudžbina</li>
                            <li>Komunikaciju s vama u vezi sa vašim narudžbinama</li>
                            <li>Unapređenje naših proizvoda i usluga</li>
                            <li>Slanje promotivnih informacija, ako ste se prijavili za njih</li>
                            <li>Analizu korišćenja našeg sajta radi poboljšanja korisničkog iskustva</li>
                        </ul>
                    </p>
                </div>

                <div className='border-bottom mt-3'>
                    <h5 className='fw-bold'>3. Dijeljenje informacija</h5>
                    <p>
                        Ne prodajemo, ne trgujemo i ne prenosimo vaše lične podatke trećim stranama bez vašeg pristanka, osim u slučaju
                        kada je to potrebno za izvršenje vaših narudžbina (npr. dostavne službe)
                    </p>
                </div>

                <div className='border-bottom mt-3'>
                    <h5 className='fw-bold'>5. Vaša prava </h5>
                    <p> Imate pravo da pristupite svojim ličnim podacima, ispravite netačne ili nepotpune informacije, zatražite brisanje svojih ličnih podataka ili brisanje vašeg naloga na platformi.
                    </p>
                </div>

                <div className='border-bottom mt-3'>
                    <h5 className='fw-bold'> 6. Promjene politike privatnosti </h5>
                    <p> Zadržavamo pravo da ažuriramo ovu Politiku privatnosti. Sve promjene biće objavljene i vidljive na ovoj stranici. </p>
                </div>


                <h5 className='fw-bold mt-3'> 7. Kontakt </h5>
                <p> Ako imate bilo kakva pitanja ili nedoumicu u vezi sa ovom Politikom privatnosti, molimo vas da nas kontaktirate putem e-pošte ili na broj telefona.
                    <div className="d-flex flex-column my-2 fw-bold">
                        <span> {companyData?.email} </span>
                        <span>  {companyData?.phone[0]} </span>
                        <span> {companyData?.phone[0]} </span>
                    </div>
                </p>

                <div className='d-none d-md-flex justify-content-center'>
                    <ImageHandler src="./images/optika-leka-logo2-removebg-preview.png" className='w-100px object-fit-cover' />
                </div>

            </div>
        </div>
    )
}
