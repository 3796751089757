import React from 'react'
import { useTranslation } from 'react-i18next';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { IBlog } from '../../../../shared/interfaces/IBlog';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../shared/functions/formatDate';
import { scrollTo } from '../../../../shared/functions/scrollTo';

interface IProps {
    blog: IBlog;
}

export default function BlogCard({ blog }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='card cursor-pointer rounded-0 border-0' onClick={() => {
            navigate(`/blog/${blog?.id}`);
            scrollTo();
        }}>
            <ImageHandler src={blog?.path}
                className='w-100 object-fit-cover h-400px' />
            <div className='card-body p-0'>
                <div className='d-flex flex-column align-items-center justify-content-center border-bottom pb-2'>
                    <h5 className='mb-0 text-truncate mt-3 '>{blog?.title}</h5>
                </div>

                <div className='mt-3 text-truncate h-50px'>
                    {blog?.shortDescription}
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <span className="hover-underline-animation text-uppercase">
                        {t('general.readMore')}
                    </span>
                    <small className='text-muted'> {formatDate(blog?.createdAt as string)}. </small>
                </div>
            </div>
        </div>
    )
}
