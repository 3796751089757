import React from 'react'
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import ProductCard from '../shop/components/ProductCard';
import EmptyList from '../../../shared/components/EmptyList';

interface IProps {
    isMyAccount?: boolean;
}

export default function Wishlist({ isMyAccount = false }: IProps) {
    const wishlist = useSelector((state: RootState) => state.wishlist);

    return (
        <>
            {/* {!isMyAccount && <LeadingTitle title={t("general.wishlist")} />} */}
            {/*
            <div className="container mb-5">
                {!!wishlist?.length ? (
                    <div className="row mt-3">
                        {wishlist?.map((product) => {
                            return (
                                <div className="col-md-3 ps-0">
                                    <ProductCard product={product} />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <EmptyList isCart={false} />
                )}
            </div> 
            */}

            <div className="row">
                {/* {!isMyAccount && <h1> Wishlist </h1>} */}
                {!!wishlist?.length ? (
                    <div className={`row ${isMyAccount ? '' : 'mt-3'}`}>
                        {wishlist?.map((product) => {
                            return (
                                <div className="col-md-4 col-lg-3 ps-0" key={product?.id}>
                                    <ProductCard product={product} />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <EmptyList isCart={false} />
                )}
            </div>
        </>
    )
}
