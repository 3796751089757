import React from 'react'
import ImpressionCard from './components/ImpressionCard'
import { comments } from '../../../../../utils/data/impressionsData'
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { useTranslation } from 'react-i18next';

export default function Impressions() {
    const { t } = useTranslation();

    return (
        <div className='col-12 my-5'>
            <h2 className="fw-bold text-center mb-md-4" data-aos="slide-right"> {t("general.impressions")} </h2>

            <div>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={4}
                    navigation={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        400: {
                            slidesPerView: 1,
                        },
                        639: {
                            slidesPerView: 2,
                        },
                        865: {
                            slidesPerView: 4,
                        },
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                >
                    {comments?.map((item) => {
                        return (
                            <SwiperSlide key={item?.id}>
                                <ImpressionCard impression={item} />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}
