import { IOrder } from "../interfaces/IOrder";
import { countNewPrice } from "./countNewPrice";

export const getTotalPriceOrder = (placedOrder: IOrder) => {
  const cartProducts = placedOrder?.items?.map((item) => item.product);
  const cartProductsQuantity = placedOrder?.items?.map(
    (item) => item.cartQuantity
  );

  let i = 0;
  const prices = cartProducts?.map((product) => {
    if (product?.discount)
      return (
        Number(
          countNewPrice(product?.price as number, product?.discount as number)
        ) * cartProductsQuantity[i]
      );
    i++;
    return (product?.price as number) * cartProductsQuantity[i];
  });

  return prices?.reduce((prev, curr) => prev + curr, 0);
};
