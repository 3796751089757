import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import FilterDrawer from '../../../../shared/components/FilterDrawer';
import Search from '../../../../shared/components/Search';
import SelectDropdown, { IOptions } from '../../../../shared/components/SelectDropdown';
import { EOrderType } from '../../../../shared/enums/EOrderType';
import { EProductSortingCriteria } from '../../../../shared/enums/ESortingCriteria';
import { handleError } from '../../../../shared/functions/handleError';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { CategoryService } from '../../categories/service';
import { ICategory } from '../../../../shared/interfaces/ICategory';
import { BrandService } from '../../brands/service';
import { IBrand } from '../../../../shared/interfaces/IBrand';

interface IProps {
    changeFilterHandler: (name: string, value: (string | number | boolean | null)) => void;
    pagination: any;
}

const searchKeys = ['title', 'sku'];

const ProductsFilter = ({ changeFilterHandler, pagination }: IProps) => {
    const { t } = useTranslation();
    const [showFilter, setShowFilter] = useState(false);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const navigate = useNavigate();
    const { pagination: filterPagination } = usePagination({ perPage: 0 });

    const [brands, setBrands] = useState<IBrand[]>([]);


    useEffect(() => {
        CategoryService.getAllCategories(filterPagination).then(response =>
            setCategories(response?.data)
        ).catch(err => handleError(err));
    }, [filterPagination]);

    useEffect(() => {
        BrandService.getAllBrands(filterPagination).then(response =>
            setBrands(response?.data)
        ).catch(err => handleError(err));
    }, [filterPagination]);

    return (
        <>
            <div className={'d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between mb-3 ms-2 ms-md-0'}>
                <Button variant="primary" className={'text-uppercase rounded-0'}
                    onClick={() => navigate('/admin/products/create')}>
                    {t('buttons.create')}
                </Button>

                <div className={'d-flex align-items-center ms-2 ms-md-0 mt-3 mt-md-0'}>
                    <div className='me-3'>
                        <h6 className={'text-uppercase cursor-pointer d-flex align-items-center mb-0'}
                            onClick={() => {
                                changeFilterHandler(
                                    'orderType',
                                    pagination?.orderType === EOrderType.ASC ? EOrderType.DESC : EOrderType.ASC
                                )
                            }}>
                            {pagination?.orderType}
                            {pagination?.orderType === EOrderType.ASC ? <RiSortAsc className={'ms-1'} /> :
                                <RiSortDesc className={'ms-1'} />
                            }
                        </h6>
                    </div>
                    <div className="border-end border-start px-3">
                        <SelectDropdown name={'orderCriteria'}
                            onChange={(event) => {
                                changeFilterHandler('orderCriteria', event?.target?.value)
                            }}
                            options={Object.values(EProductSortingCriteria).map(criteria => ({
                                value: criteria,
                                name: t(`general.${criteria}`)
                            }))}
                        />
                    </div>
                    <h6 className={'text-uppercase cursor-pointer hover-underline-animation mb-0 ms-3'}
                        onClick={() => setShowFilter(true)}>
                        {t("general.filters")}
                    </h6>
                </div>
            </div>

            <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
                <>
                    <div className="my-3">
                        <SelectDropdown name={'categoryId'}
                            defaultLabel={t('general.filterByCategory')}
                            onChange={(event) => {
                                changeFilterHandler('categoryId', event?.target?.value)
                            }}
                            options={categories?.map(category => ({
                                value: category?.id,
                                name: category?.name
                            })) as IOptions[]}
                        />
                    </div>

                    <div className="mb-3">
                        <SelectDropdown name={'brandProductId'}
                            defaultLabel={t('general.filterByBrand')}
                            onChange={(event) => {
                                changeFilterHandler('brandProductId', event?.target?.value)
                            }}
                            options={brands?.map(brand => ({
                                value: brand?.id as number,
                                name: brand?.name
                            }))}
                        />
                    </div>

                    <Search changeFilterHandler={changeFilterHandler} keys={searchKeys} />
                </>
            </FilterDrawer>
        </>
    );
};

export default ProductsFilter;