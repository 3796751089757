import { useEffect, useState } from 'react'
import ProductCard from '../../shop/components/ProductCard';
//swiper
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { ICategory } from '../../../../shared/interfaces/ICategory';
import { useTranslation } from 'react-i18next';
import Aos from 'aos';

interface IProps {
    categories: ICategory[];
}

export default function BestSellers({ categories }: IProps) {
    const [activeCategory, setActiveCategory] = useState<ICategory | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        const activeCategoryButton = categories?.find(category => category?.products && category?.isFeatured && category?.products?.length > 0)
        setActiveCategory(activeCategoryButton as ICategory);
    }, [categories]);


    return (
        <>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <h2 className='fw-bold mt-3 mt-md-5' data-aos="slide-right"> {t("general.bestSellers")} </h2>
                <div className='d-flex flex-column flex-md-row mt-3 mb-5'>
                    {categories?.map(category =>
                        !!category?.isFeatured && !!category?.products?.length && (
                            <button key={category?.id} className={`btn border-primary rounded-0 
                                ${activeCategory?.id === category?.id ? 'text-white bg-primary' : 'text-primary bg-white'} text-uppercase mb-2 mb-md-0 me-md-3`}
                                onClick={() => {
                                    setActiveCategory(category);
                                }}> {category?.name} </button>
                        )
                    )}
                </div>
            </div>

            <Swiper
                spaceBetween={25}
                slidesPerView={4}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 1,
                    },
                    639: {
                        slidesPerView: 2,
                    },
                    865: {
                        slidesPerView: 4,
                    },
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
            >
                {activeCategory?.products?.map((product) => {
                    return (
                        <SwiperSlide key={product?.id}>
                            <ProductCard product={product} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    )
}
