import React from "react";
import { useTranslation } from "react-i18next";
import ImageHandler from "../../../shared/components/ImageHandler";
import { getTotalYears } from "../../../shared/functions/getTotalYears";
import { companyData } from "../../../utils/data/companyData";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";


export default function About() {
  const { t } = useTranslation();
  const nbCustomers = 50;

  return <div className="row justify-content-center align-items-center mt-5">
    <div className="col-12 d-flex justify-content-center">
      <ImageHandler src="./images/optika-leka-logo2.jpeg" className=" object-fit-cover about-logo" />
    </div>

    <div className="col-lg-9 mt-5" >
      <p> {t("about.firstParagraph")}</p>
      <p> {t("about.secondParagraph")}</p>
      <p> {t("about.thirdParagraph")}</p>
    </div>

    <div className="col-md-6 col-lg-3 d-flex flex-column justify-content-center align-items-center my-5">
      <div className="card rounded-5 p-3 border-0 bg-gray w-100">
        <h3 className="fw-bold mb-0"> {getTotalYears()}</h3>
        <span> {t("general.yearsOfExperience")} </span>
      </div>
      <div className="card rounded-5 p-3 border-0 bg-gray w-100 mt-3">
        <h3 className="fw-bold mb-0"> +{nbCustomers}K </h3>
        <span> {t("general.contentBuyers")}</span>
      </div>
    </div>

    <div className="col-12 mt-md-5 mb-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <CiLocationOn className='wh-30px text-primary mb-1' />
        <div className="text-center cursor-pointer">
          <a href="https://maps.app.goo.gl/zaMDxbXR2ntaZsVF9" target="_blank" rel="noreferrer">
            <p className="mb-1 small"> {companyData?.street} </p>
            <p className="mb-1 small"> {companyData?.city}, {companyData?.zipCode} </p>
          </a>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center my-3">
        <FiPhoneCall className='wh-30px text-primary mb-1' />
        <p className="mb-1 small"> {companyData?.phone[0]} </p>
        <p className="mb-1 small"> {companyData?.phone[1]} </p>
      </div>

      <div className="d-flex justify-content-center">
        <a href={companyData?.instagramLink} target="_blank" rel="noreferrer">
          <FaInstagram className="wh-30px me-3 cursor-pointer text-primary" />
        </a>
        <a href={companyData?.fbLink} target="_blank" rel="noreferrer">
          <FaFacebook className="wh-30px cursor-pointer text-primary" />
        </a>
      </div>
    </div>
  </div >;
}
