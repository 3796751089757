import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';
import { EOrderType } from '../../../../shared/enums/EOrderType';
import FilterDrawer from '../../../../shared/components/FilterDrawer';
import Search from '../../../../shared/components/Search';
import SelectDropdown from '../../../../shared/components/SelectDropdown';


interface IProps {
    changeFilterHandler: (name: string, value: string | number | boolean | null) => void;
    pagination: any;
}

const searchKeys = ['title'];
const featured = [{
    id: 1,
    name: "general.yes",
    value: "true"
},
{
    id: 2,
    name: "general.no",
    value: "false"
}]

export default function CategoriesFilter({ changeFilterHandler, pagination }: IProps) {
    const [showFilter, setShowFilter] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div className='d-flex align-items-center me-2 me-md-0'>
                <div className="border-end px-3">
                    <h6 className='text-uppercase cursor-pointer d-flex align-items-center'
                        onClick={() => {
                            changeFilterHandler('orderType', pagination?.orderType === EOrderType.ASC ? EOrderType.DESC : EOrderType.ASC);
                        }}>
                        {pagination?.orderType}
                        {pagination?.orderType === EOrderType.ASC ? (
                            <RiSortAsc className={'ms-1'} />
                        ) : (
                            <RiSortDesc className={'ms-1'} />
                        )}
                    </h6>
                </div>

                <h6
                    className={
                        'text-uppercase cursor-pointer hover-underline-animation ms-3'
                    }
                    onClick={() => setShowFilter(true)}
                >
                    {t('general.filters')}
                </h6>
            </div>

            <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
                <>
                    <SelectDropdown name={'isFeatured'}
                        defaultLabel={t('general.isFeatured')}
                        onChange={(event) => {
                            changeFilterHandler('isFeatured', event?.target?.value)
                        }}
                        options={featured?.map(item => ({
                            value: item?.value,
                            name: t(item?.name)
                        }))}
                        className='form-select mb-3 border-0'
                    />

                    <Search changeFilterHandler={changeFilterHandler} keys={searchKeys} />
                </>
            </FilterDrawer>
        </>)
}
