import { Navigate } from "react-router-dom";
import AdminBlog from "../../../app/admin/blog";
import CreateBlogPost from "../../../app/admin/blog/components/CreateBlogPost";
import ViewBlogPost from "../../../app/admin/blog/components/ViewBlogPost";
import Brands from "../../../app/admin/brands";
import CreateBrand from "../../../app/admin/brands/components/CreateBrand";
import UpdateBrand from "../../../app/admin/brands/components/UpdateBrand";
import AdminCategories from "../../../app/admin/categories";
import CreateCategory from "../../../app/admin/categories/components/CreateCategory";
import UpdateCategory from "../../../app/admin/categories/components/UpdateCategory";
import AdminDashboard from "../../../app/admin/dashboard";
import AdminFAQ from "../../../app/admin/faq";
import CreateFaq from "../../../app/admin/faq/components/CreateFaq";
import UpdateFaq from "../../../app/admin/faq/components/UpdateFaq";
import AdminOrders from "../../../app/admin/orders";
import SingleOrder from "../../../app/admin/orders/single-order";
import AdminProducts from "../../../app/admin/products";
import CreateProduct from "../../../app/admin/products/components/CreateProduct";
import SingleProduct from "../../../app/admin/products/single-product";
import AdminUsers from "../../../app/admin/users";
import UpdateUser from "../../../app/admin/users/components/UpdateUser";
import NotFound from "../../../authentification/404";
import MyAccount from "../../../shared/myaccount/MyAccount";

export const adminRoutes = [
  {
    path: "/admin/dashboard",
    showInHeader: true,
    title: "navigation.dashboard",
    component: <AdminDashboard />,
  },
  {
    path: "/admin/products",
    showInHeader: true,
    title: "navigation.products",
    component: <AdminProducts />
  },
  {
    path: "/admin/products/edit/:id",
    component: <SingleProduct />
  },
  {
    path: "/admin/products/create",
    component: <CreateProduct />
  },
  {
    path: "/admin/categories",
    showInHeader: true,
    title: "navigation.categories",
    component: <AdminCategories />
  },
  {
    path: "/admin/category/create",
    component: <CreateCategory />
  },
  {
    path: "/admin/category/edit/:id",
    component: <UpdateCategory />
  },
  {
    path: "/admin/brands",
    showInHeader: true,
    title: "navigation.brands",
    component: <Brands />
  },
  {
    path: "/admin/brand/edit/:id",
    component: <UpdateBrand />,
  },
  {
    path: "/admin/brand/create",
    component: <CreateBrand />,
  },
  {
    path: "/admin/users",
    showInHeader: true,
    title: "navigation.users",
    component: <AdminUsers />
  },
  {
    path: "/admin/users/edit/:id",
    component: <UpdateUser />
  },
  {
    path: "/admin/orders",
    showInHeader: true,
    title: "navigation.orders",
    component: <AdminOrders />
  },
  {
    path: "/admin/orders/edit/:id",
    component: <SingleOrder />
  },
  {
    path: "/myaccount",
    title: "general.myAccount",
    component: <MyAccount />
  },
  {
    path: "/admin/blog",
    showInHeader: true,
    title: "Blog",
    component: <AdminBlog />
  },
  {
    path: "/admin/blog/create",
    component: <CreateBlogPost />
  },
  {
    path: "/admin/blog/edit/:id",
    component: <ViewBlogPost />
  },
  {
    path: "/admin/faq",
    showInHeader: true,
    title: "FAQ",
    component: <AdminFAQ />
  },
  {
    path: "/admin/faq/create",
    component: <CreateFaq />
  },
  {
    path: "/admin/faq/edit/:id",
    component: <UpdateFaq />
  },
  {
    path: "*",
    component: <Navigate to="/admin/dashboard" />
  }
];
