import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { handleError } from '../../../../shared/functions/handleError';
import { IProduct } from '../../../../shared/interfaces/IProduct';
import { CategoryService } from '../../../admin/categories/service';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { useParams } from 'react-router-dom';
import ProductCard from '../../shop/components/ProductCard';
import { ProductService } from '../../../admin/products/service';

interface IProps {
    categoryId: number
}

export default function RelatedProducts({ categoryId }: IProps) {
    const [products, setProducts] = useState<IProduct[]>([])
    const { id } = useParams();

    useEffect(() => {
        if (categoryId && id) {
            CategoryService.getCategoryById(categoryId).then(response => {
                setProducts(response?.products?.filter(product => product?.id !== +id) as IProduct[]);
            }).catch(err => handleError(err));
        }
    }, [categoryId, id]);
    console.log(products, 'response');

    return (
        <Col md={12} className={'mt-3'}>
            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 1,
                    },
                    639: {
                        slidesPerView: 3,
                    },
                    865: {
                        slidesPerView: 4
                    },
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
            >
                {products?.map((product) => {
                    return <SwiperSlide key={product?.id}>
                        <ProductCard product={product} />
                    </SwiperSlide>
                })}
            </Swiper>
        </Col>
    );
}
