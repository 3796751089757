import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import { IOrder } from '../../../../shared/interfaces/IOrder';
import { OrdersService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import SingleOrderTable from './components/SingleOrderTable';
import { Col, Row } from 'react-bootstrap';
import SingleOrderDetails from './components/SingleOrderDetails';

export default function SingleOrder() {
    const { id } = useParams();
    const { userData } = useSelector((state: RootState) => state.auth);
    const [currentOrder, setCurrentOrder] = useState<IOrder | null>(null);

    useEffect(() => {
        if (id && userData?.id) {
            OrdersService.getSingleOrder(+id, userData?.id).then(response => {
                setCurrentOrder(response)
            }).catch(err => handleError(err));
        }
    }, [id, userData?.id]);

    return (
        <>
            {currentOrder?.id && <Row>
                <Col md={8}>
                    <SingleOrderTable order={currentOrder as IOrder} />
                </Col>
                <Col md={4}>
                    <SingleOrderDetails order={currentOrder} />
                </Col>
            </Row>}
        </>
    )
}
