import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UsersService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { IUser } from '../../../../shared/interfaces/IUser';
import { Form } from 'react-bootstrap';
import UserForm from './UserForm';
import { useTranslation } from 'react-i18next';
import { multipartFormData } from '../../../../utils/api/api.service';
import { PROFILE_URL } from '../../../../utils/api/endpoints';
import { toast } from 'react-toastify';

export default function UpdateUser() {
    const { id } = useParams();
    const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = () => {
        multipartFormData(file, `${PROFILE_URL}/${id}`, selectedUser, 'put', 'file').then((response) => {
            if (response) {
                navigate(-1);
                toast.success(t("general.successfullyUpdated"));
            }
            setSelectedUser(null);
        }).catch(err => handleError(err));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSelectedUser((prev) => ({ ...prev, [name]: value }) as IUser);
    }

    useEffect(() => {
        !!id && UsersService.getUserById(+id).then((response) => {
            setSelectedUser(response);
        }).catch(err => handleError(err));
    }, [id]);

    return (
        <Form onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
        }}>
            <UserForm user={selectedUser} handleChange={handleChange} file={file} setFile={setFile} />
        </Form>
    )
}
