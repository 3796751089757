import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useState } from "react";
import { IUser } from "../../../interfaces/IUser";
import { IUpdatePassword } from "../../../interfaces/IUpdatePassword";
import { multipartFormData } from "../../../../utils/api/api.service";
import { PROFILE_URL } from "../../../../utils/api/endpoints";
import authSlice from "../../../../store/slices/auth.slice";
import { toast } from "react-toastify";
import { handleError } from "../../../functions/handleError";
import { UsersService } from "../../../../app/admin/users/service";
import PersonalForm from "./PersonalForm";
import { PasswordForm } from "./PasswordForm";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ERole } from "../../../enums/ERole";


export default function AccountDetails() {
    const { t } = useTranslation();
    const { userData } = useSelector((state: RootState) => state.auth);
    const [currentUser, setCurrentUser] = useState<IUser | null>(userData as IUser);
    const [file, setFile] = useState<File | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAdmin = currentUser?.role === ERole.ADMIN;
    const [passwordState, setPasswordState] = useState<IUpdatePassword>({
        oldPassword: "",
        newPassword: ""
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCurrentUser((prev) => ({ ...prev, [name]: value } as IUser))
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPasswordState(prev => ({ ...prev, [name]: value }));
    }

    const onSubmitPersonal = () => {
        multipartFormData(file, PROFILE_URL, currentUser, "put", "file").then((response) => {
            const { data } = response;
            dispatch(authSlice.actions.setLoginState({
                isAuthenticated: true,
                userData: data
            }));
            toast.success(t("general.successfullyUpdated"));
            navigate(-1);
        }).catch(err => handleError(err));
    };

    const onSubmitPassword = () => {
        UsersService.userChangePassword(passwordState, userData?.id as number).then(() => {
            toast.success(t("general.successfullyUpdated"));
            setPasswordState({
                oldPassword: "",
                newPassword: ""
            });
        }).catch(err => handleError(err));
    }

    return (
        <>
            {!isAdmin && <Form
                onSubmit={(event) => {
                    event.preventDefault();
                    onSubmitPersonal();
                }}
            >
                <PersonalForm
                    currentUser={currentUser}
                    handleChange={handleChange}
                    file={file}
                    setFile={setFile}
                    userData={userData}
                />
            </Form>
            }
            <Form
                className={isAdmin ? '' : 'mt-5'}
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitPassword();
                }}
            >
                <PasswordForm
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    handleChangePassword={handleChangePassword}
                    passwordState={passwordState}
                />
            </Form>
        </>
    )
}
