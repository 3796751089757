export const checkoutSteps = [
  {
    id: 1,
    name: "cart.shoppingBag",
    description: "cart.manageList",
    title: "cart.cart",
  },
  {
    id: 2,
    name: "cart.shippingAndCheckout",
    description: "cart.checkoutList",
    title: "cart.shippingAndCheckout",
  },
  {
    id: 3,
    name: "cart.confirmation",
    description: "cart.confirmationMess",
    title: "cart.orderReceived",
  },
];
