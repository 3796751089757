import React, { SetStateAction, useState } from 'react'
import { ICreateOrder, IOrder } from '../../../../shared/interfaces/IOrder';
import { IUser } from '../../../../shared/interfaces/IUser';
import { IProduct } from '../../../../shared/interfaces/IProduct';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import OrderDetails from './OrderDetails';
import RegisterForm from '../../../../authentification/register/components/RegisterForm';
import { OrdersService } from '../../../admin/orders/service';
import { toast } from 'react-toastify';
import cartSlice from '../../../../store/slices/cart.slice';
import { handleError } from '../../../../shared/functions/handleError';
import { checkoutSteps } from '../../../../utils/data/checkoutSteps';
import { scrollTo } from '../../../../shared/functions/scrollTo';
import { IRegisterInput } from '../../../../shared/interfaces/IRegisterInput';
import { FieldErrors, UseFormRegister } from 'react-hook-form';


interface IProps {
    register: UseFormRegister<IRegisterInput>
    currentUser: IUser | null,
    errors: FieldErrors<IRegisterInput>
    cart: IProduct[],
    setCurrentStep: React.Dispatch<SetStateAction<number>>;
    setPlacedOrder: React.Dispatch<SetStateAction<IOrder | null>>;
}

export default function Checkout({ register, currentUser, cart, setCurrentStep, setPlacedOrder, errors }: IProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handlePlaceOrder = () => {
        const orderData = cart?.map((product) => ({
            productId: product?.id,
            quantity: product?.cartQuantity
        })) as ICreateOrder[];

        setIsLoading(true);

        OrdersService.createOrder(orderData)
            .then(response => {
                if (response) {
                    toast.success(t("general.successfullyPlacedOrder"));
                    setPlacedOrder({
                        ...response,
                        items: response?.items?.map((product) => ({
                            ...product,
                            cartQuantity: product?.quantity
                        })),
                    });
                    setCurrentStep(prev => prev + 1);
                    dispatch(cartSlice.actions.setCart([]));
                    localStorage.removeItem("cart");
                }
            })
            .catch(err => handleError(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Row className={"mt-md-5"}>
            <Col lg={8} className={"px-0"}>
                <div className='d-md-none ms-3'>
                    <h5 className="text-uppercase mb-0">
                        02 {t(checkoutSteps[1]?.name)}
                    </h5>
                    <small className="text-muted">
                        {t(checkoutSteps[1]?.description)}
                    </small>
                </div>
                <h5 className={"d-none d-md-block text-uppercase"}> {t("cart.billingDetails")} </h5>
                <RegisterForm
                    register={register}
                    isDisabled errors={errors}
                    currentUser={currentUser}
                />
            </Col>
            <Col lg={4} className={"px-0"}>
                <OrderDetails cart={cart} />
                <div className={"d-flex justify-content-between justify-content-md-end my-3 "}>

                    <Button
                        className={"d-block bg-secondary text-white border-0 d-md-none text-uppercase w-50 ms-2 ms-md-0 ms-lg-5 p-2 me-2 me-md-0 rounded-0"}
                        onClick={() => {
                            setCurrentStep(prev => prev - 1);
                            scrollTo();
                        }}
                    >
                        {t("buttons.back")}
                    </Button>

                    <Button
                        variant="primary"
                        className={"text-uppercase w-100 ms-2 ms-md-0 ms-lg-5 p-2 me-2 me-md-0 rounded-0"}
                        onClick={() => {
                            handlePlaceOrder();
                            scrollTo();
                        }}
                        disabled={isLoading}
                    >
                        {isLoading && (
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                        {t("buttons.placeOrder")}
                    </Button>
                </div>
            </Col>
        </Row>
    )
}
