import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IBlog } from '../../../../shared/interfaces/IBlog';
import { handleError } from '../../../../shared/functions/handleError';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { formatDate } from '../../../../shared/functions/formatDate';
import { BlogService } from '../../../admin/blog/service';

export default function BlogPost() {
    const { id } = useParams();
    const [currentBlogPost, setCurrentBlogPost] = useState<IBlog | null>(null);

    useEffect(() => {
        !!id && BlogService.getBlogById(+id).then((response) => {
            setCurrentBlogPost(response);
        }).catch(err => handleError(err));
    }, [id]);

    return (
        <div className='mb-5'>
            <div className="text-center">
                <h1> {currentBlogPost?.title} </h1>
                <div className="text-muted">
                    <small> {formatDate(currentBlogPost?.createdAt as string)} </small>
                </div>
            </div>

            <ImageHandler src={currentBlogPost?.path as string} className="w-100 h-400px object-fit-cover mt-3 mb-5" />
            <div dangerouslySetInnerHTML={{ __html: currentBlogPost?.description as string }} className='quill-content' />
        </div>
    )
}
