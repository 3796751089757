import { createSlice } from "@reduxjs/toolkit";
import { ERole } from "../../shared/enums/ERole";
import { IUser } from "../../shared/interfaces/IUser";

interface AuthState {
  isAuthenticated: boolean;
  token: string;
  userData?: IUser;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: "",
  userData: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyName: "",
    country: "",
    streetAddress: "",
    city: "",
    zipCode: "",
    phone: "",
    profile: "",
    role: ERole.USER,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginState: (state, action) => {
      state = action.payload;
      return state;
    },
    setLogoutState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export default authSlice;
