import React, { useEffect, useState } from 'react'
import { IBlog } from '../../../shared/interfaces/IBlog';
import { BlogService } from './service';
import { usePagination } from '../../../shared/hooks/usePagination';
import { handleError } from '../../../shared/functions/handleError';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BlogFilter from './components/BlogFilter';
import SharedTable from '../../../shared/components/SharedTable';
import { blogTableHeader } from './table/blogTableHeader';
import Pagination from '../../../shared/components/Pagination';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { toast } from 'react-toastify';

export default function AdminBlog() {
    const [blogs, setBlogs] = useState<IBlog[]>([]);
    const {
        pagination,
        count,
        setCount,
        changeFilterHandler,
        totalCount,
        setTotalCount } = usePagination({
            perPage: 10,
        });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedBlog, setSelectedBlog] = useState<IBlog | null>(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

    const handleClose = () => {
        setIsDeleteOpen(false);
        setSelectedBlog(null);
    }

    const handleConfirmDelete = () => {
        BlogService.deleteBlog(selectedBlog?.id as number).then(response => {
            if (response) {
                changeFilterHandler('updatedAt', new Date().getTime());
                setIsDeleteOpen(false);
                toast.success(t("general.successfullyDeleted"));
            }
        })
    }

    useEffect(() => {
        BlogService.getAllBlogPosts(pagination)
            .then((response) => {
                setBlogs(response?.data);
                setCount(Math.ceil(response?.count / response?.perPage));
                setTotalCount(response?.count);
            }).catch(err => handleError(err))
    }, [pagination, setCount, setTotalCount]);

    return (
        <div>
            <div className="mb-3">
                <Breadcrumbs />
            </div>

            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Button variant="primary"
                    className="text-uppercase ms-2 ms-md-0 rounded-0"
                    onClick={() => navigate("/admin/blog/create")}>
                    {t("buttons.create")}
                </Button>

                <BlogFilter changeFilterHandler={changeFilterHandler} />
            </div>

            <SharedTable
                data={blogs}
                headers={blogTableHeader}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className='text-center'> {t("general.listEmpty")} </div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === "delete") {
                        setSelectedBlog(data);
                        setIsDeleteOpen(true);
                    }

                    if (btnName === 'view') {
                        navigate(`/admin/blog/edit/${data?.id}`);
                    }
                }}
            />

            <ConfirmationModal
                show={isDeleteOpen}
                handleClose={handleClose}
                handleConfirm={handleConfirmDelete}
                title={selectedBlog?.title as string}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />
        </div>
    )
}
