export const footerData = [
  {
    id: 1,
    title: "footer.company",
    items: [
      { id: 1, name: "footer.blog", navigateTo: "/blog" },
      { id: 2, name: "footer.contactUs", navigateTo: "/contact" },
    ],
  },
  {
    id: 2,
    title: "footer.shop",
    items: [{ id: 1, name: "footer.shopAll", navigateTo: "/shop" }],
  },
  {
    id: 3,
    title: "footer.help",
    items: [
      { id: 1, name: "footer.legalPrivacy", navigateTo: "/privacy" },
      { id: 2, name: "footer.contact", navigateTo: "/contact" },
      { id: 3, name: "footer.faq", navigateTo: "/faq" },
    ],
  },
  {
    id: 4,
    title: "footer.categories",
    items: [
      { id: 1, name: "categories.eyeglasses", navigateTo: "/" },
      { id: 2, name: "categories.women", navigateTo: "/" },
      { id: 3, name: "categories.men", navigateTo: "/" },
      { id: 4, name: "categories.multifocals", navigateTo: "/" },
      { id: 5, name: "categories.designer", navigateTo: "/" },
    ],
  },
];
