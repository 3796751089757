import { IPagination } from "../../../shared/interfaces/IPagination";
import { IUpdatePassword } from "../../../shared/interfaces/IUpdatePassword";
import { IUser } from "../../../shared/interfaces/IUser";
import { requests } from "../../../utils/api/api.service";
import {
  ADMIN_USER_URL,
  ADMIN_USERS_URL,
  PROFILE_URL,
  USER_PASSWORD,
} from "../../../utils/api/endpoints";

export const UsersService = {
  getAllUsers: (
    pagination: IPagination
  ): Promise<{ data: IUser[]; count: number; perPage: number }> =>
    requests.get(ADMIN_USERS_URL, pagination),

  getUserById: (userId: number): Promise<IUser> =>
    requests.get(`${ADMIN_USER_URL}/${userId}`),

  updateUser: (payload: IUser, userId: number) =>
    requests.put(`${PROFILE_URL}/${userId}`, payload),

  userChangePassword: (payload: IUpdatePassword, userId: number) =>
    requests.put(`${USER_PASSWORD}/${userId}`, payload),

  deleteUser: (userId: number) => requests.delete(`${PROFILE_URL}/${userId}`),
};
