import React from 'react'
import { Form } from 'react-bootstrap'
import ProductForm from './ProductForm';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_PRODUCTS } from '../../../../utils/api/endpoints';
import { handleError } from '../../../../shared/functions/handleError';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { IBrand } from '../../../../shared/interfaces/IBrand';
import { IPhoto } from '../../../../shared/interfaces/IPhoto';
import { ICategory } from '../../../../shared/interfaces/ICategory';

export const productSchema = yup.object().shape({
    name: yup.string().required("error.error-required"),
    description: yup.string().required("error.error-required"),
    price: yup.number().typeError("error.error-type-number").required("error.error-required"),
    sku: yup.string().required("error.error-required"),
    quantity: yup.number().typeError("error.error-type-number").required("error.error-required"),
    categoryId: yup.number().typeError("error.error-type-category").required("error.error-required"),
    brandProductId: yup.number().typeError("error.error-type-brand").required("error.error-required"),
    files: yup.mixed().required("error.error-required"),
})

export interface ICreateProduct {
    name: string;
    id?: number;
    price: number;
    description: string;
    sku: string;
    quantity: number;
    categoryId: number;
    brandProduct?: IBrand;
    brandProductId: number;
    additionalDescription?: string;
    color?: string;
    discount?: number;
    files: File[];
    productPhotos?: IPhoto[];
    category?: ICategory;
}

export default function CreateProduct() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, getValues, watch } = useForm<ICreateProduct>({
        resolver:
            yupResolver(productSchema) as unknown as Resolver<ICreateProduct, any>
    });

    const handleSubmitProduct = (formData: ICreateProduct) => {
        multipartFormData(formData?.files && formData?.files, ADMIN_PRODUCTS, formData, 'post', 'files', true).then((response) => {
            if (response) {
                toast.success(t("general.successfullyCreated"));
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }

    return (
        <Form
            onSubmit={handleSubmit(handleSubmitProduct)}
        >
            <ProductForm
                currentProduct={getValues()}
                errors={errors}
                watch={watch}
                register={register}
            />
        </Form>
    )
}