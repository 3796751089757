import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePagination } from '../../../../shared/hooks/usePagination';
import { IUser } from '../../../../shared/interfaces/IUser';
import { UsersService } from '../../users/service';
import { handleError } from '../../../../shared/functions/handleError';
import Breadcrumbs from '../../../../shared/components/Breadcrumbs';
import SelectDropdown from '../../../../shared/components/SelectDropdown';
import { ESortingCriteria } from '../../../../shared/enums/ESortingCriteria';
import FilterDrawer from '../../../../shared/components/FilterDrawer';
import { EOrderStatus } from '../../../../shared/enums/EOrderStatus';
import { EOrderType } from '../../../../shared/enums/EOrderType';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';

interface IProps {
    changeFilterHandler: (name: string, value: (string | number | boolean | null)) => void;
    orderPagination: any;
}

export default function OrderFilter({ changeFilterHandler, orderPagination }: IProps) {
    const { t } = useTranslation();
    const [showFilter, setShowFilter] = useState(false);
    const { pagination } = usePagination({ perPage: 0 });
    const [userList, setUserList] = useState<IUser[]>([]);

    useEffect(() => {
        UsersService.getAllUsers(pagination).then((response) => {
            setUserList(response?.data);
        }).catch(err => handleError(err))
    }, [pagination]);

    return (
        <>
            <div className={'d-flex flex-column flex-md-row align-items-center justify-content-between mb-3'}>
                <Breadcrumbs />
                <div className={'d-flex align-items-center'}>
                    <div className="me-3">
                        <h6 className={'text-uppercase cursor-pointer d-flex align-items-center mb-0'}
                            onClick={() => {
                                changeFilterHandler(
                                    'orderType',
                                    orderPagination?.orderType === EOrderType.ASC ? EOrderType.DESC : EOrderType.ASC
                                )
                            }}>
                            {orderPagination?.orderType}
                            {orderPagination?.orderType === EOrderType.ASC ? <RiSortAsc className={'ms-1'} /> :
                                <RiSortDesc className={'ms-1'} />
                            }
                        </h6>
                    </div>
                    <div className="border-start border-end px-3">
                        <SelectDropdown name={'orderCriteria'}
                            onChange={(event) => {
                                changeFilterHandler('orderCriteria', event?.target?.value)
                            }}
                            options={Object.values(ESortingCriteria)?.map(criteria => ({
                                value: criteria,
                                name: t(`general.${criteria}`)
                            }))}
                        />
                    </div>
                    <h6 className={'text-uppercase cursor-pointer hover-underline-animation mb-0 ms-3'}
                        onClick={() => setShowFilter(true)}>
                        {t("general.filters")}
                    </h6>
                </div>
            </div>
            <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
                <>
                    <div className="mt-3">
                        <SelectDropdown name={'status'}
                            defaultLabel={t('general.filterByStatus')}
                            onChange={(event) => {
                                changeFilterHandler('status', event?.target?.value)
                            }}
                            options={Object.values(EOrderStatus)?.map(status => ({
                                value: status,
                                name: status
                            }))}
                        />
                    </div>
                    <div className="mt-3">
                        <SelectDropdown
                            defaultLabel={t('general.filterByUser')}
                            onChange={(event) => changeFilterHandler('userId', event?.target?.value)}
                            options={
                                userList?.map(user => ({
                                    name: `${user?.firstName} ${user?.lastName}`,
                                    value: Number(user?.id)
                                }))
                            }
                        />
                    </div>
                </>
            </FilterDrawer>

        </>
    )
}
