import React from 'react'
import { ICategory } from '../../../../shared/interfaces/ICategory';
import { Button } from 'react-bootstrap';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

interface IProps {
    currentCategory: ICategory | null,
    localFile: File | null,
    register: UseFormRegister<ICategory>,
    errors: FieldErrors<ICategory>,
}

export default function CategoryForm({ currentCategory, register, errors, localFile }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="px-2 px-md-0">
            <input
                className={`form-control rounded-0 p-3 ${errors.name && 'border-danger'}`}
                placeholder={t('general.categoryName')}
                {...register("name")}
            />
            {errors?.name && <div className='text-danger small mb-3'> {t(`${errors.name.message}`)}</div>}


            <div className='d-flex align-items-center my-3'>
                <label className='form-label mb-0' htmlFor="isFeaturedCategory"> {t("general.isFeatured")} </label>
                <input
                    className='form-check-input ms-3'
                    type="checkbox"
                    {...register("isFeatured")}
                />
            </div>

            <input
                className="form-control mt-2 rounded-0 p-3"
                type="file"
                multiple={false}
                required={!(!!currentCategory?.id)}
                {...register("file")}
            />
            {errors.file && <div className='text-danger small mb-3'> {t(`${errors.file.message}`)}</div>}


            {(localFile || currentCategory?.path) &&
                <ImageHandler src={String(localFile ? URL.createObjectURL(localFile) : currentCategory?.path)}
                    className="w-100 h-400px object-fit-contain mt-2" />}

            <div className='d-flex justify-content-end align-items-center mb-5 mt-3'>
                <Button onClick={() => {
                    navigate(-1);
                }} className="text-uppercase bg-secondary text-white border-0 rounded-0 me-2">
                    {t('buttons.cancel')}
                </Button>
                <Button variant="primary" type="submit" className="text-uppercase border-0 rounded-0">
                    {t(`buttons.${currentCategory?.id ? 'update' : 'create'}`)}
                </Button>
            </div>
        </div>
    )
}
