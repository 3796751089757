import React from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { ProductService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_PRODUCTS } from '../../../../utils/api/endpoints';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ProductForm from '../components/ProductForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { ICreateProduct, productSchema } from '../components/CreateProduct';

export default function SingleProduct() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, formState: { errors }, getValues, watch, setValue } = useForm<ICreateProduct>({
        resolver:
            yupResolver(productSchema) as unknown as Resolver<ICreateProduct, any>,
        defaultValues: async () => {
            const data = await ProductService.getProductById(Number(id));
            return {
                ...data,
                categoryId: data?.category?.id,
                brandProductId: data?.brandProduct?.id
            } as ICreateProduct
        }
    });

    const handleSubmitProduct = (currentProduct: ICreateProduct) => {
        let dataToSend = { ...currentProduct };
        delete dataToSend.productPhotos;
        // @ts-ignore
        delete dataToSend.files;

        multipartFormData(currentProduct?.files && currentProduct?.files, `${ADMIN_PRODUCTS}/${currentProduct?.id}`, dataToSend, 'put', 'files', true).then((response) => {
            if (response) {
                toast.success(t("general.successfullyUpdated"));
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }

    return (
        <Form
            onSubmit={handleSubmit(handleSubmitProduct)}
        >
            <ProductForm
                watch={watch}
                currentProduct={getValues()}
                errors={errors}
                register={register}
                setValue={setValue}
            />
        </Form>
    )
}