export const informationHome = [
  {
    id: 1,
    title: "general.freeDelivery",
    desc: "messages.freeDeliveryMessage",
  },
  {
    id: 2,
    title: "general.exchange",
    desc: "messages.exchangeMessage",
  },
  {
    id: 3,
    title: "general.location",
    desc: "messages.locationMessage",
  },
  {
    id: 4,
    title: "general.customerService",
    desc: "messages.customerServiceMessage",
  },
];
