import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

interface IProps {
  children: JSX.Element;
}

const Layout = ({ children }: IProps) => {
  const { pathname } = useLocation();
  const routesWithoutContainer = ["/"]

  return (
    <div className="layout-container">
      <Header />
      <div className={`${routesWithoutContainer?.includes(pathname) ? '' : 'container mt-100px'} px-0`}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
