import React from "react";
import GetInTouch from "./components/GetInTouch";
import { companyData } from "../../../utils/data/companyData";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-12 d-flex flex-column justify-content-center align-items-center mb-3 my-md-5">
        <h2 className="mb-0"> {t("general.getInTouch")} </h2>
        <small className="text-center"> {t("messages.contactUs")} </small>
      </div>
      <div className="col-md-6">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2833.2112252017764!2d19.212859077451807!3d44.75610678040266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475be8e3d8cd3541%3A0x7bf85ff90bdf4f14!2sMilo%C5%A1a%20Crnjanskog%20127%2C%20Bijeljina%2076300!5e0!3m2!1sen!2sba!4v1726492404396!5m2!1sen!2sba"
          className="w-100 h-100"
          title="google map optika leka"
          style={{ border: '0px' }}
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div className="col-md-6 mt-5 mt-md-0">
        <GetInTouch />
      </div>

      <div className="col-12 mt-md-5 mb-5">
        <h2 className="text-center mt-5 mb-3"> {t("general.followUs")} </h2>
        <div className="d-flex justify-content-center">
          <a href={companyData?.instagramLink} target="_blank" rel="noreferrer">
            <FaInstagram className="wh-30px me-3 cursor-pointer text-primary" />
          </a>
          <a href={companyData?.fbLink} target="_blank" rel="noreferrer">
            <FaFacebook className="wh-30px cursor-pointer text-primary" />
          </a>
        </div>
      </div>
    </div>);
}
