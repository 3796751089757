import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en.json";
import sr from "./languages/sr.json";
import de from "./languages/de.json";

export const resources = {
  sr: {
    translation: sr,
  },

  en: {
    translation: en,
  },

  de: {
    translation: de,
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  defaultNS: "translation",
  fallbackLng: "sr",
});

export default i18n;
