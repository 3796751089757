import React, { useEffect } from "react";
import { useCachingState } from "../../../shared/hooks/useCachingState";
import { IBlog } from "../../../shared/interfaces/IBlog";
import { usePagination } from "../../../shared/hooks/usePagination";
import { handleError } from "../../../shared/functions/handleError";
import BlogCard from "./components/BlogCard";
import { Col, Row } from "react-bootstrap";
import BlogFilter from "./components/BlogFilter";
import { useTranslation } from "react-i18next";
import { BlogService } from "../../admin/blog/service";

export default function Blog() {
  const [blogPosts, setBlogPosts] = useCachingState<IBlog[]>(
    "blogListUser",
    []
  );
  const { pagination, changeFilterHandler } = usePagination({ perPage: 0 });
  const { t } = useTranslation();

  useEffect(() => {
    BlogService.getAllBlogPosts(pagination)
      .then((response) => {
        setBlogPosts(response?.data);
      })
      .catch((err) => handleError(err));
  }, [pagination, setBlogPosts]);

  return <>
    <Row>
      <div className={"col-12 d-flex justify-content-end align-items-center px-0"}>
        <BlogFilter changeFilterHandler={changeFilterHandler} />
      </div>
    </Row>

    <Row className="mb-5">
      {!!blogPosts?.length ? (
        blogPosts?.map(blog => {
          return (
            <Col md={6} lg={4} key={blog?.id} className="my-3 ps-md-0">
              <BlogCard blog={blog} />
            </Col>
          )
        })) :
        (<div> {t("general.noResults")} </div>)
      }
    </Row>
  </>;
}
