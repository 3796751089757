import React, { SetStateAction } from 'react'
import { IProduct } from '../../../../shared/interfaces/IProduct'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { toast } from 'react-toastify';
import cartSlice from '../../../../store/slices/cart.slice';
import wishlistSlice from '../../../../store/slices/wishlist.slice';
import Breadcrumbs from '../../../../shared/components/Breadcrumbs';
import { Button, Col } from 'react-bootstrap';
import { formatPrice } from '../../../../shared/functions/formatPrice';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { countNewPrice } from '../../../../shared/functions/countNewPrice';

interface IProps {
    product: IProduct;
    setProduct: React.Dispatch<SetStateAction<IProduct>>;
}

export default function ProductInfo({ product, setProduct }: IProps) {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const wishlist = useSelector((state: RootState) => state.wishlist);
    const cart = useSelector((state: RootState) => state.cart);

    const isProductInCart = cart?.some(product => product?.id === Number(id));
    const isWishlist = wishlist?.some(product => product?.id === Number(id));

    const newPrice = countNewPrice(product?.price as number, product?.discount as number);

    const onIncrement = (productId: Number) => {
        if (product?.quantity < Number(product?.cartQuantity)) {
            toast.warning("No quantity");
            return;
        }

        setProduct((prev) => ({
            ...prev,
            cartQuantity: Number(prev?.cartQuantity) + 1,
        }))
    }

    const onDecrement = (productId: number) => {
        if (1 > Number(product?.cartQuantity)) {
            toast.warning("Min 1");
            return;
        }
        setProduct((prev) => ({
            ...prev,
            cartQuantity: Number(prev?.cartQuantity) - 1,
        }))
    }

    const handleAddToCart = (product: IProduct, isInCart = false) => {
        if (isInCart) {
            const updatedList = cart?.map((item) => {
                if (item?.id === product?.id) {
                    return {
                        ...item,
                        cartQuantity: product?.cartQuantity
                    };
                }
                return item;
            });
            dispatch(cartSlice.actions.setCart(updatedList));
            localStorage.setItem("cart", JSON.stringify(updatedList));
        } else {
            const updatedList = cart?.concat(product as IProduct);
            dispatch(cartSlice.actions.setCart(updatedList));
            localStorage.setItem("cart", JSON.stringify(updatedList));
        }
    }

    const handleWishlistClick = (productId: number, isInList = false) => {
        if (isInList) {
            const updatedList = wishlist.filter((item) => item.id !== productId);
            dispatch(wishlistSlice.actions.setWishlist(updatedList));
            localStorage.setItem("wishlist", JSON.stringify(updatedList));
        } else {
            const updatedList = wishlist.concat({
                ...product,
                cartQuantity: 1,
            } as IProduct);
            dispatch(wishlistSlice.actions.setWishlist(updatedList));
            localStorage.setItem("wishlist", JSON.stringify(updatedList));
        }
    };

    return (
        <>
            <Col md={6} className='position-relative'>
                <Breadcrumbs />
                <div className='border-bottom pb-4 mt-3'>
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className='mb-0'> {product?.name} </h2>

                        <div className='bg-white wh-50px d-flex align-items-center justify-content-center rounded-circle border'
                            onClick={() => handleWishlistClick(Number(product?.id), isWishlist)} >
                            {isWishlist ?
                                (<FaHeart className="text-danger cursor-pointer wh-20px" />)
                                :
                                (<FaRegHeart className="cursor-pointer text-muted wh-20px" />)}
                        </div>
                    </div>

                    {!!product?.discount ? (
                        <div className="d-flex align-items-center">
                            <h5 className="text-primary text-decoration-line-through"> {formatPrice(product?.price as number)}</h5>
                            <h5 className="text-danger fw-bold ms-3"> {formatPrice(+newPrice)}</h5>
                        </div>
                    ) : (<h5 className="text-primary fw-bold"> {formatPrice(product?.price as number)}</h5>)}
                </div>
                <p className={"mt-md-4 text-muted "}>{product?.description}</p>

                <div className="d-flex flex-column mt-3">
                    <span className={"text-muted small"}>
                        SKU: <span className="text-black ms-2">{product?.sku || "-"}</span>
                    </span>
                    <span className={"text-muted small"}>
                        {t("general.category")}:
                        <span className="text-black ms-2">
                            {product?.category?.name || "-"}
                        </span>
                    </span>
                    {!!product?.color &&
                        <span className='text-muted small'>
                            {t("general.color")}:
                            <span className="text-black ms-2"> {product?.color}</span>
                        </span>}

                    {!!product?.brandProduct?.name &&
                        <span className="text-muted small">
                            {t("general.brand")}:
                            <span className="text-black ms-2">
                                {product?.brandProduct?.name}
                            </span>
                        </span>}
                </div>

                <div className="d-flex align-items-center position-absolute add-to-cart-product">
                    <div className="d-flex align-items-center justify-content-center border border-2 p-1">
                        <button
                            className="cart-btn"
                            onClick={() => onDecrement(Number(product?.id))}
                            disabled={product?.cartQuantity === 1}
                        >
                            -
                        </button>
                        <p
                            className="mb-0 text-muted d-flex justify-content-center"
                            style={{ width: 15 }}
                        >
                            {product?.cartQuantity}
                        </p>
                        <button
                            className="cart-btn"
                            disabled={
                                Number(product?.quantity) <= Number(product?.cartQuantity)
                            }
                            onClick={() => onIncrement(Number(product?.id))}
                        >
                            +
                        </button>
                    </div>
                    <Button
                        variant={"primary"}
                        onClick={() => handleAddToCart(product, isProductInCart)}
                        className="text-uppercase p-3 flex-grow-1 ms-2 rounded-0 "
                    >
                        {t(isProductInCart ? "buttons.alreadyAdded" : "buttons.addToCart")}
                    </Button>
                </div>
            </Col>

            <Col md={12} className={"mt-5 additional-desc"}>
                <div className="w-100  d-flex justify-content-center flex-column align-items-center">
                    {!!product?.additionalDescription && (
                        <>
                            <h4
                                className={
                                    "hover-underline-animation text-center cursor-pointer mt-5 mt-md-4 mb-0"
                                }
                            >
                                {t("table.additionalDescription")}
                            </h4>
                            <p className={"w-75 mt-1 text-center text-break mb-0"}>
                                {product?.additionalDescription}
                            </p>
                        </>
                    )}
                </div>
            </Col>
        </>
    )
}
