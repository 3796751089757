import React, { useEffect, useState } from 'react'
import SharedTable from '../../../shared/components/SharedTable'
import { IOrder } from '../../../shared/interfaces/IOrder';
import { usePagination } from '../../../shared/hooks/usePagination';
import { OrdersService } from './service';
import { handleError } from '../../../shared/functions/handleError';
import { orderTableHeader } from './table/orderTableHeader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../shared/components/Pagination';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { toast } from 'react-toastify';
import OrderFilter from './components/OrderFilter';

export default function AdminOrders() {
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

    const { pagination, count, totalCount, setTotalCount, setCount, changeFilterHandler } = usePagination({
        perPage: 10,
        paginationKeys: {
            orderType: 'DESC',
        },
    })
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleDeleteOrder = () => {
        selectedOrder?.id && OrdersService.deleteOrder(selectedOrder?.id as number).then((response) => {
            if (response?.success) {
                setSelectedOrder(null);
                toast.success(t("general.successfullyDeleted"));
                setIsDeleteOpen(false);
                changeFilterHandler('updatedAt', new Date().getTime());
            }
        }).catch(err => handleError(err));
    }

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setSelectedOrder(null);
    }

    useEffect(() => {
        OrdersService.getAllOrders(pagination).then((response) => {
            setOrders(response?.data);
            setCount(Math.ceil(response.count / response.perPage));
            setTotalCount(response?.count);
        }).catch(err => handleError(err));
    }, [pagination, setCount, setTotalCount, setOrders]);

    return (
        <div>
            <OrderFilter
                changeFilterHandler={changeFilterHandler}
                orderPagination={pagination}
            />
            <SharedTable
                headers={orderTableHeader}
                data={orders}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className={'text-center'}>{t('general.listEmpty')}</div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'delete') {
                        setSelectedOrder(data);
                        setIsDeleteOpen(true);
                    }
                    if (btnName === 'view') {
                        navigate(`/admin/orders/edit/${data?.id}`);
                    }
                }}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />

            <ConfirmationModal
                handleClose={onDeleteClose}
                handleConfirm={handleDeleteOrder}
                title={`Order ID: ${selectedOrder?.id}`}
                show={isDeleteOpen}
            />
        </div>
    )
}
