export const companyData = {
  name: "Optika Leka",
  street: "Miloša Crnjanskog 127",
  email: "optikaleka@gmail.com",
  city: "Bijeljina",
  zipCode: "76300",
  phone: ["055 226-126", "065 527-331"],
  fbLink:
    "https://www.facebook.com/p/Leka-Optika-100089816389397/?locale=hr_HR",
  instagramLink: "https://www.instagram.com/optikaleka/",
};
