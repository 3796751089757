export const comments = [
  {
    id: 1,
    comment: '"Izvrsna i stručna usluga. Brzo sam pronašla savršene naočare."',
    username: "Ana Jovanović",
  },
  {
    id: 2,
    comment:
      '"Širok asortiman i pristupačne cijene. Veoma sam zadovoljan svojom kupovinom."',
    username: "Marko Nikolić",
  },
  {
    id: 3,
    comment: '"Brza usluga i ljubazno osoblje. Izbor okvira je odličan."',
    username: "Milena Petrović",
  },
  {
    id: 4,
    comment:
      '"Profesionalna usluga i brza dostava. Rado ću se vratiti ponovo."',
    username: "Stefan Marković",
  },
  {
    id: 5,
    comment:
      '"Odličan kvalitet naočara i brzo mjerenje dioptrije. Topla preporuka!"',
    username: "Jelena Savić",
  },
  {
    id: 6,
    comment:
      '"Uvijek ljubazni i spremni da pomognu. Uživam u svojim novim naočarima."',
    username: "Vladimir Rajić",
  },
  {
    id: 7,
    comment:
      '"Dobar izbor sunčanih naočara po povoljnim cenama. Uvek se vraćam ovde."',
    username: "Marija Đorđević",
  },
  {
    id: 8,
    comment:
      '"Mama prezadovoljna, izmamili smo joj kez. Hvala vam puno, ostajem vjerni kupac!"',
    username: "Milena Petrović",
  },
];
