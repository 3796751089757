import { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterDrawer from "../../../../shared/components/FilterDrawer";
import Search from "../../../../shared/components/Search";

interface IProps {
    changeFilterHandler: (
        name: string,
        value: string | number | boolean | null
    ) => void;
}
const searchKeys = ["title"];

export default function BlogFilter({ changeFilterHandler }: IProps) {
    const [showFilter, setShowFilter] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <h6
                className={
                    "text-uppercase cursor-pointer hover-underline-animation mb-0 me-2 me-md-0"
                }
                onClick={() => setShowFilter(true)}
            >
                {t("general.filters")}
            </h6>

            <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
                <Search changeFilterHandler={changeFilterHandler} keys={searchKeys} />
            </FilterDrawer>
        </>
    );
}
