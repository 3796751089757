import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ImageHandler from './ImageHandler';
import Fancybox from './Fancybox';
import { ICreateProduct } from '../../app/admin/products/components/CreateProduct';
import { FieldErrors, UseFormRegister } from 'react-hook-form';



interface IProp {
    files?: File[];
    isMultiple: boolean;
    isRequired: boolean;
    formatType: string;
    register: UseFormRegister<ICreateProduct>
    errors: FieldErrors<ICreateProduct>
}

export default function FileUpload({ register, errors, files, isMultiple = false, isRequired = false, formatType = "image/*" }: IProp) {
    return (
        <>
            <input type="file"
                multiple={isMultiple}
                required={isRequired}
                accept={formatType}
                className={'form-control mb-3 rounded-0 p-3'}
                {...register('files')}
            />
            {errors?.files && <div> {errors?.files?.message} </div>}

            <Fancybox
                options={{
                    Carousel: {
                        infinite: false,
                    },
                }}
            >
                <Row className='border-bottom mb-3'>
                    {files && [...files]?.map((file, index) => {
                        return (
                            <Col md={4} key={index} className={'mb-3 ps-0'}>
                                <a data-fancybox="gallery" href={URL.createObjectURL(file)}>
                                    <ImageHandler key={index} className={'product-order-img cursor-pointer border'}
                                        src={URL.createObjectURL(file)} />
                                </a>
                            </Col>
                        )
                    })}
                </Row>
            </Fancybox>
        </>
    )
}
