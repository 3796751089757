import React, { SetStateAction } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { IUpdatePassword } from "../../../interfaces/IUpdatePassword";

interface IProps {
    showPassword: boolean;
    setShowPassword: React.Dispatch<SetStateAction<boolean>>;
    handleChangePassword: React.ChangeEventHandler<HTMLInputElement>;
    passwordState: IUpdatePassword;
}

export function PasswordForm({
    showPassword,
    setShowPassword,
    handleChangePassword,
    passwordState,
}: IProps) {
    const { t } = useTranslation();

    return (
        <div className="row ">
            <div className="col-12 px-0">
                <h6 className="text-uppercase mb-3">
                    {t("general.passwordChange")}
                </h6>
            </div>
            <div className="col-md-6 px-0 pe-md-2">
                <Form.Group controlId="formBasicPassword">
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="oldPassword"
                            value={passwordState?.oldPassword}
                            placeholder={t("general.currentPassword")}
                            className="rounded-0 mb-3 p-3"
                            onChange={handleChangePassword}
                        />

                        <InputGroup.Text className="mb-3 rounded-0">
                            {!showPassword ? (
                                <VscEye
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setShowPassword((prev) => !prev);
                                    }}
                                />
                            ) : (
                                <VscEyeClosed
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setShowPassword((prev) => !prev);
                                    }}
                                />
                            )}
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </div>

            <div className="col-md-6 px-0">
                <Form.Group controlId="formBasicPassword">
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="newPassword"
                            value={passwordState?.newPassword}
                            placeholder={t("general.newPassword")}
                            className="rounded-0 mb-3 p-3"
                            onChange={handleChangePassword}
                        />

                        <InputGroup.Text className="mb-3 rounded-0">
                            {!showPassword ? (
                                <VscEye
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setShowPassword((prev) => !prev);
                                    }}
                                />
                            ) : (
                                <VscEyeClosed
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setShowPassword((prev) => !prev);
                                    }}
                                />
                            )}
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </div>
            <div className="col-md-6 px-0">
                <div className='d-flex justify-content-center justify-content-md-start'>
                    <Button className="w-50 rounded-0" type="submit">
                        {t("buttons.save")}
                    </Button>
                </div>
            </div>
        </div>
    );
}
