import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { IOrder } from '../../interfaces/IOrder';
import { OrdersService } from '../../../app/admin/orders/service';
import { handleError } from '../../functions/handleError';
import SharedTable from '../../components/SharedTable';
import { useTranslation } from 'react-i18next';
import { scrollTo } from '../../functions/scrollTo';
import { useNavigate } from 'react-router-dom';

const userOrdersHeaders = [
    { key: 'id', label: 'id' },
    { key: 'createdAt', label: 'createdAt' },
    { key: 'user.firstName', label: 'firstName' },
    { key: 'user.lastName', label: 'lastName' },
    { key: 'status', label: 'status' },
    { key: 'total', label: 'total' }
];

export default function UserOrders() {
    const { userData } = useSelector((state: RootState) => state.auth);
    const [userOrders, setUserOrders] = useState<IOrder[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        OrdersService.getOrdersByUserId(userData?.id as number).then((response) => {
            setUserOrders(response);
        }).catch(err => handleError(err));
    }, [userData?.id]);

    return (
        <div>
            <SharedTable
                data={userOrders?.sort((a, b) => b.id - a.id)}
                headers={userOrdersHeaders}
                ctaHeaders={['actions']}
                ctaButtons={['view']}
                emptyState={
                    <div className='text-center'>
                        {t("general.listEmpty")}
                    </div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'view') {
                        scrollTo();
                        navigate(`/users/orders/${data?.id}`)
                    }
                }}
            />
        </div>
    )
}
