//swiper
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { IProduct } from '../../../../shared/interfaces/IProduct';
import ProductCard from '../../shop/components/ProductCard';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';


interface IProps {
    products: IProduct[];
}

export default function FeaturedProducts({ products }: IProps) {
    const { t } = useTranslation();

    // useEffect(() => {
    //     AOS.init({
    //         duration: 1000,
    //         easing: 'ease-in-out'
    //     });
    // }, [])

    return (
        <div className="row my-5">
            <div className="col-12">
                <h2 className="fw-bold text-center mb-4 mb-lg-5"
                    data-aos="slide-right"> {t("general.featuredProducts")} </h2>

                <Swiper
                    spaceBetween={25}
                    slidesPerView={4}
                    navigation={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        400: {
                            slidesPerView: 1,
                        },
                        639: {
                            slidesPerView: 2,
                        },
                        865: {
                            slidesPerView: 4,
                        },
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                >
                    {products?.map((product) => {
                        return (
                            <SwiperSlide key={product?.id}>
                                <ProductCard product={product} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    )
}
