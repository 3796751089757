import { ICategory } from "../../../shared/interfaces/ICategory";
import { IPagination } from "../../../shared/interfaces/IPagination";
import { requests } from "../../../utils/api/api.service";
import { ADMIN_CATEGORIES } from "../../../utils/api/endpoints";

export const CategoryService = {
  getAllCategories: (
    pagination: IPagination
  ): Promise<{ data: ICategory[]; count: number; perPage: number }> =>
    requests.get(ADMIN_CATEGORIES, pagination),

  getCategoryById: (categoryId: number): Promise<ICategory> =>
    requests.get(`${ADMIN_CATEGORIES}/${categoryId}`),

  updateCategory: (payload: ICategory, categoryId: number) =>
    requests.put(`${ADMIN_CATEGORIES}/${categoryId}`, payload),

  deleteCategory: (categoryId: number) =>
    requests.delete(`${ADMIN_CATEGORIES}/${categoryId}`),

  createCategory: (payload: ICategory) =>
    requests.post(ADMIN_CATEGORIES, payload),
};
