import React, { SetStateAction } from 'react'
import { IProduct } from '../../../../shared/interfaces/IProduct';
import { IPhoto } from '../../../../shared/interfaces/IPhoto';
import Fancybox from '../../../../shared/components/Fancybox';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import ImageHandler from '../../../../shared/components/ImageHandler';

interface IProps {
    product: IProduct;
    currentImage: IPhoto;
    activeImageId: number;
    setActiveImageId: React.Dispatch<SetStateAction<number>>;
}

export default function ProductFancybox({ product, activeImageId, setActiveImageId, currentImage }: IProps) {

    return (
        <Fancybox
            options={{
                Carousel: {
                    infinite: false,
                },
            }}>

            <div className='position-relative'>
                <button className="product-button left"
                    disabled={!!(product?.productPhotos?.length && product?.productPhotos?.[0]?.id === currentImage?.id)}
                    onClick={() => {
                        const currentIndex = product?.productPhotos?.findIndex(img => img.id === activeImageId);
                        setActiveImageId(product?.productPhotos[currentIndex - 1]?.id as number);
                    }}
                >
                    <BsChevronCompactLeft className={'text-muted wh-30px'} />
                </button>

                <a data-fancybox="gallery" href={currentImage?.path}>
                    <ImageHandler src={currentImage?.path} className='product-img-big cursor-pointer' />
                </a>
                <button
                    disabled={!!(product?.productPhotos?.length && product?.productPhotos?.[product?.productPhotos?.length - 1]?.id === currentImage?.id)}

                    className={'product-button right'} onClick={() => {
                        const currentIndex = product?.productPhotos?.findIndex(picture => picture.id === activeImageId)
                        setActiveImageId(product?.productPhotos[currentIndex! + 1]?.id as number)
                    }}>
                    <BsChevronCompactRight className={'text-muted wh-30px'} />
                </button>

            </div>
        </Fancybox>
    )
}
