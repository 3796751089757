import About from "../../../app/public/about";
import Blog from "../../../app/public/blog";
import BlogPost from "../../../app/public/blog/components/BlogPost";
import Cart from "../../../app/public/cart";
import Contact from "../../../app/public/contact";
import FAQ from "../../../app/public/faq";
import Home from "../../../app/public/home";
import Policy from "../../../app/public/policy";
import SingleProduct from "../../../app/public/product";
import Promo from "../../../app/public/promo";
import Shop from "../../../app/public/shop";
import Wishlist from "../../../app/public/wishlist";
import NotFound from "../../../authentification/404";
import Login from "../../../authentification/login";
import Register from "../../../authentification/register";
import SingleUserOrder from "../../../shared/myaccount/components/SingleUserOrder";
import MyAccount from "../../../shared/myaccount/MyAccount";

export const publicRoutes = [
  {
    path: "/",
    showInHeader: true,
    title: "navigation.home",
    component: <Home />,
  },
  {
    path: "/shop",
    showInHeader: true,
    title: "navigation.shop",
    component: <Shop />,
  },
  {
    path: "/promo",
    showInHeader: true,
    title: "navigation.promo",
    component: <Promo />,
  },
  {
    path: "/about",
    showInHeader: true,
    title: "navigation.about",
    component: <About />,
  },
  {
    path: "/blog",
    showInHeader: true,
    title: "navigation.blog",
    component: <Blog />,
  },
  {
    path: "/contact",
    showInHeader: true,
    title: "navigation.contact",
    component: <Contact />,
  },
  {
    path: "/login",
    showInHeader: true,
    title: "login.signIn",
    component: <Login />,
  },
  {
    path: "/register",
    title: "login.register",
    component: <Register />,
  },
  {
    path: "/faq",
    title: "footer.faq",
    component: <FAQ />,
  },
  {
    path: "/privacy",
    title: "footer.legalPrivacy",
    component: <Policy />,
  },
  {
    path: "/blog/:id",
    component: <BlogPost />
  },
  {
    path: '/product/:id',
    component: <SingleProduct />,
  },
  {
    path: "/myaccount",
    title: "general.myAccount",
    component: <MyAccount />
  },
  {
    path: '/users/orders/:id',
    component: <SingleUserOrder />,
  },
  {
    path: "/users/cart",
    component: <Cart />
  },
  {
    path: "/users/wishlist",
    component: <Wishlist />
  },
  {
    path: "*",
    component: <NotFound />,
  },
];
