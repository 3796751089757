import { useEffect, useState } from 'react'
import { usePagination } from '../../../shared/hooks/usePagination';
import { FAQService } from '../../admin/faq/service';
import { IFaq } from '../../../shared/interfaces/IFaq';
import { Accordion } from 'react-bootstrap';

export default function FAQ() {
    const { pagination, setCount } = usePagination({
        perPage: 0,
    });
    const [listFAQ, setListFAQ] = useState<IFaq[]>([])


    useEffect(() => {
        FAQService.getAllFaq(pagination).then(response => {
            setListFAQ(response?.data);
            setCount(Math.ceil(response.count / response.perPage));
        })
    }, [pagination, setCount]);

    return (
        <div>
            <div className="row">
                {listFAQ?.map((question) => {
                    return (
                        <div className="col-12">
                            <Accordion defaultActiveKey={String(listFAQ?.[0]?.id)} className="accordion-flush" key={question?.id}>
                                <Accordion.Item key={question?.id} eventKey={String(question?.id)} className={'mb-3 rounded-0 border-bottom'}>
                                    <Accordion.Header>{question?.name}</Accordion.Header>
                                    <Accordion.Body> {question?.description} </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
