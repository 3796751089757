import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FilterFaq from './components/FilterFaq';
import { usePagination } from '../../../shared/hooks/usePagination';
import SharedTable from '../../../shared/components/SharedTable';
import { IFaq } from '../../../shared/interfaces/IFaq';
import { FAQService } from './service';
import { handleError } from '../../../shared/functions/handleError';
import { faqTableHeaders } from './table/faqTableHeaders';
import Pagination from '../../../shared/components/Pagination';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { toast } from 'react-toastify';

export default function AdminFAQ() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [faqs, setFaqs] = useState<IFaq[]>([]);
    const [selectedFaq, setSelectedFaq] = useState<IFaq | null>(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

    const { pagination, changeFilterHandler, count, totalCount, setCount, setTotalCount } = usePagination({
        perPage: 10,
    })

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setSelectedFaq(null);
    }

    const handleDelete = () => {
        FAQService.deleteFaq(selectedFaq?.id as number).then(response => {
            if (response) {
                toast.success(t("general.successfullyDeleted"));
                setIsDeleteOpen(false);
                changeFilterHandler('updatedAt', new Date().getTime())
            }
        }).catch(err => handleError(err));
    }

    useEffect(() => {
        FAQService.getAllFaq(pagination).then((response) => {
            setFaqs(response?.data);
            setCount(Math.ceil(response.count / response.perPage));
            setTotalCount(response?.count);
        }).catch(err => handleError(err));
    }, [pagination, setCount, setTotalCount, setFaqs])

    return (
        <div>
            <div className="mb-3">
                <Breadcrumbs />
            </div>

            <div className={'d-flex align-items-center justify-content-between mb-3'}>
                <Button
                    variant="primary"
                    className={'text-uppercase rounded-0 ms-2 ms-md-0'}
                    onClick={() => {
                        navigate('/admin/faq/create');
                    }}
                >
                    {t('buttons.create')}
                </Button>

                <FilterFaq changeFilterHandler={changeFilterHandler} />
            </div>


            <SharedTable
                data={faqs}
                headers={faqTableHeaders}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className='text-center'> {t("general.listEmpty")} </div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === "delete") {
                        setSelectedFaq(data);
                        setIsDeleteOpen(true);
                    }

                    if (btnName === "view") {
                        navigate(`/admin/faq/edit/${data?.id}`)
                    }
                }}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />

            <ConfirmationModal
                show={isDeleteOpen}
                handleClose={onDeleteClose}
                handleConfirm={handleDelete}
                title={String(selectedFaq?.name)}
            />
        </div>
    )
}
