import HomeMainImage from "./components/HomeMainImage";
import HomeCollections from "./components/HomeCollections";
import BestSellers from "./components/BestSellers";
import ImageHandler from "../../../shared/components/ImageHandler";
import Blogs from "./components/Blogs";
import FeaturedProducts from "./components/FeaturedProducts";
import { ICategory } from "../../../shared/interfaces/ICategory";
import { useEffect, useState } from "react";
import { CategoryService } from "../../admin/categories/service";
import { handleError } from "../../../shared/functions/handleError";
import { flatten } from "lodash";
import PopularBrands from "./components/PopularBrands";
import Impressions from "./components/impressions";
import ContactHome from "./components/ContactHome";
import { IProduct } from "../../../shared/interfaces/IProduct";
import InformationHome from "./components/InformationHome";

export default function Home() {
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    CategoryService.getAllCategories({ page: 1, perPage: 0 }).then(response => {
      setCategories(response?.data);
    }).catch(err => handleError(err));
  }, []);

  return <>
    <div className="row">
      <div className="col-md-12 px-0">
        <HomeMainImage />
      </div>
    </div>

    <div className="container">
      <FeaturedProducts products={flatten(categories?.map(category => category?.products)) as IProduct[]} />
      <HomeCollections />

      <div className="row">
        <div className="col-12">
          <BestSellers categories={categories} />
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-md-12 px-0 position-relative">
        {/* <ImageHandler src="https://images.unsplash.com/photo-1511499767150-a48a237f0083?q=80&w=1760&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          className="w-100 h-500px object-fit-cover opacity-5" /> */}
        <ImageHandler src="./images/optikapregled.jpeg"
          className="w-100 h-550px object-fit-cover opacity-5 bg-blur" />
        <div className="container position-absolute contact-position">
          <div className="row" data-aos="fade-down">
            <ContactHome />
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="row my-5">
        <PopularBrands />
        <Impressions />
        <Blogs />
        <InformationHome />
      </div>
    </div>

  </>;
}
