import React, { SetStateAction } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { IUser } from '../../../../shared/interfaces/IUser';

interface IProps {
    user: IUser | null,
    handleChange: React.ChangeEventHandler<HTMLInputElement>,
    file: File | null,
    setFile: React.Dispatch<SetStateAction<File | null>>
}

export default function UserForm({ user, handleChange, file, setFile }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='row px-2 px-md-0'>
            <div className="col-md-8 ps-0">
                <Form.Control type="text"
                    placeholder={t("general.firstName")}
                    name="firstName"
                    value={user?.firstName}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
                <Form.Control type="text"
                    placeholder={t("general.lastName")}
                    name="lastName"
                    value={user?.lastName}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
                <Form.Control type="text"
                    placeholder={t("general.username")}
                    name="profile"
                    value={user?.profile}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
            </div>
            <div className="col-md-4 border-dashhed-gray mb-3 px-0 d-flex justify-content-center h-200px">
                <label>
                    {file ?
                        <ImageHandler
                            className="object-fit-contain w-100 cursor-pointer h-100 p-2"
                            src={URL.createObjectURL(file)}
                        /> :
                        <ImageHandler
                            errorPath='https://t4.ftcdn.net/jpg/05/42/36/11/360_F_542361185_VFRJWpR2FH5OiAEVveWO7oZnfSccZfD3.jpg'
                            className="object-fit-contain w-100 cursor-pointer h-100 p-2"
                            src={user?.profilePhoto?.path as string} />
                    }
                    <input type="file"
                        name="profilePhoto"
                        onChange={(e) => setFile(e?.target?.files?.[0] || null)}
                        accept='image/*'
                        style={{ display: "none" }} />
                </label>
            </div>


            <div className="col-12 px-0">
                <Form.Control type="email"
                    placeholder={t("general.email")}
                    name="email"
                    value={user?.email}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
            </div>

            <div className="col-12 px-0">
                <Form.Control type="text"
                    placeholder={t("general.phone")}
                    name="phone"
                    value={user?.phone}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
            </div>

            <div className="col-12 px-0">
                <Form.Control type="text"
                    placeholder={t("general.street")}
                    name="streetAddress"
                    value={user?.streetAddress}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
            </div>
            <div className="col-12 px-0">
                <Form.Control type="text"
                    placeholder={t("general.city")}
                    name="city"
                    value={user?.city}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
            </div>
            <div className="col-12 px-0">
                <Form.Control type="text"
                    placeholder={t("general.country")}
                    name="country"
                    value={user?.country}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
            </div>
            <div className="col-12 px-0">
                <Form.Control type="text"
                    placeholder={t("general.zipCode")}
                    name="zipCode"
                    value={user?.zipCode}
                    onChange={handleChange}
                    className="rounded-0 mb-3 p-3"
                />
            </div>
            <div className="d-flex justify-content-end p-0 mb-5">
                <Button className='bg-secondary border-0 text-uppercase rounded-0' onClick={() => navigate(-1)}> {t("buttons.cancel")}</Button>
                <Button variant='primary' className="ms-2 text-uppercase rounded-0" type="submit"> {t("buttons.update")}</Button>
            </div>
        </div>
    )
}
