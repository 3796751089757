import React from 'react'
import { IOrder } from '../../../../../shared/interfaces/IOrder'
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import ImageHandler from '../../../../../shared/components/ImageHandler';
import { formatPrice } from '../../../../../shared/functions/formatPrice';
import { countNewPrice } from '../../../../../shared/functions/countNewPrice';

interface IProps {
    order: IOrder | null
}

export default function SingleOrderTable({ order }: IProps) {
    const { t } = useTranslation();

    return (
        <div className={'mb-3'}>
            <Row className={'border-bottom py-2 mb-3 '}>
                <Col xs={6}>
                    <h6 className={'text-uppercase'}>{t('navigation.products')}</h6>
                </Col>
                <Col xs={3} md={2} className={'px-0'}>
                    <h6 className={'text-uppercase'}>{t('general.price')}</h6>
                </Col>
                <Col xs={3} md={2} className={'px-0'}>
                    <h6 className={'text-uppercase'}>{t('general.quantity')}</h6>
                </Col>
                <Col xs={1} md={2} className={'px-0 d-none d-md-block'}>
                    <h6 className={'text-uppercase'}>{t('general.subtotal')}</h6>
                </Col>
            </Row>

            {order?.items?.map((item, index) => {
                const isLastElement = index === order?.items?.length - 1
                return (
                    <Row key={item?.id} className={`pb-3 ${isLastElement ? '' : 'mb-3 border-bottom'}`}>
                        <Col xs={6} md={6} className={'ps-0 '}>
                            <Row>
                                <Col md={6} className={'ps-0 d-none d-md-block'}>
                                    <ImageHandler
                                        className={'product-order-img default-radius'}
                                        src={item?.product?.productPhotos?.[0]?.path} />

                                </Col>
                                <Col md={6}
                                    className={'d-none d-md-flex justify-content-center flex-column'}>
                                    <p className="mb-0 fw-bold">
                                        {item?.product?.name || '-'}
                                    </p>

                                    <p className="mb-0 text-muted">
                                        {t('general.description')}: {item?.product?.name || '-'}
                                    </p>
                                    <p className="mb-0 text-muted">
                                        SKU: {item?.product?.sku}
                                    </p>
                                </Col>

                                <Col md={6} className="d-flex flex-column d-md-none">
                                    <p className="mb-0 fw-bold">
                                        {item?.product?.name || '-'}
                                    </p>
                                    <p className="text-muted small">
                                        SKU: {item?.product?.sku}
                                    </p>
                                    <ImageHandler
                                        className={'product-order-img default-radius'}
                                        src={item?.product?.productPhotos?.[0]?.path} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={5} md={2} className={'ps-0 d-flex align-items-center'}>
                            {!!item?.originalDiscount ? (
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <small className="text-muted text-decoration-line-through"> {formatPrice(item?.originalPrice as number)}</small>
                                    <p className='mb-0 text-muted'> {formatPrice((+countNewPrice(item?.originalPrice as number, item?.originalDiscount as number)))}</p>
                                </div>
                            ) : (<p className="mb-0 text-muted">{formatPrice((item?.originalPrice as number))}
                            </p>)}

                        </Col>
                        <Col xs={1} md={2} className={'ps-0 d-flex align-items-center justify-content-center'}>
                            <p className="mb-0 text-muted">
                                {item?.quantity}
                            </p>
                        </Col>
                        <Col xs={2} md={2} className={'ps-0 d-none d-md-flex align-items-center'}>
                            <p className="mb-0 fw-bold">
                                {formatPrice((+countNewPrice(item?.originalPrice as number, item?.originalDiscount as number) * item?.quantity) || 0)}
                            </p>
                        </Col>
                    </Row>
                )
            })}

            <div className={'border-bottom py-2 bg-secondary pe-3'}>
                <h6 className={'text-end text-white mb-0 fw-bold'}>
                    TOTAL: {formatPrice(
                        order?.items?.reduce((prev, curr) => prev +
                            (
                                +countNewPrice(curr?.originalPrice as number, curr?.originalDiscount as number) * curr?.quantity
                            ), 0) as number
                    )}
                </h6>
            </div>
        </div>
    );
}
