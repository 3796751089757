import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../../shared/components/Breadcrumbs';
import { EOrderType } from '../../../../shared/enums/EOrderType';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';
import SelectDropdown from '../../../../shared/components/SelectDropdown';
import { ESortingCriteria } from '../../../../shared/enums/ESortingCriteria';
import FilterDrawer from '../../../../shared/components/FilterDrawer';
import Search from '../../../../shared/components/Search';

interface IProps {
    changeFilterHandler: (name: string, value: (string | number | boolean | null)) => void;
    pagination: any;
}

const searchKeys = ["firstName", "lastName", "email"];

const UserFilter = ({ changeFilterHandler, pagination }: IProps) => {
    const { t } = useTranslation();
    const [showFilter, setShowFilter] = useState(false);

    return (
        <>
            <div className={'d-flex flex-column flex-md-row align-items-center justify-content-between mb-3'}>
                <Breadcrumbs />
                <div className={'d-flex align-items-center'}>
                    <div className="me-3">
                        <h6 className={'text-uppercase cursor-pointer d-flex align-items-center mb-0'}
                            onClick={() => {
                                changeFilterHandler(
                                    'orderType',
                                    pagination?.orderType === EOrderType.ASC ? EOrderType.DESC : EOrderType.ASC
                                )
                            }}>
                            {pagination?.orderType}
                            {pagination?.orderType === EOrderType.ASC ? <RiSortAsc className={'ms-1'} /> :
                                <RiSortDesc className={'ms-1'} />
                            }
                        </h6>
                    </div>
                    <div className="border-end border-start px-3">
                        <SelectDropdown name={'orderCriteria'}
                            onChange={(event) => {
                                changeFilterHandler('orderCriteria', event?.target?.value)
                            }}
                            options={Object.values(ESortingCriteria)?.filter(criteria => criteria !== "total")?.map(criteria => ({
                                value: criteria,
                                name: t(`general.${criteria}`)
                            }))}
                        />
                    </div>
                    <h6 className={'text-uppercase cursor-pointer hover-underline-animation mb-0 ms-3'}
                        onClick={() => setShowFilter(true)}>
                        {t("general.filters")}
                    </h6>
                </div>
            </div>

            <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
                <Search changeFilterHandler={changeFilterHandler} keys={searchKeys} />
            </FilterDrawer>
        </>
    );
};

export default UserFilter;
