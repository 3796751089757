import React, { useEffect, useState } from "react";
import { IProduct } from "../../../shared/interfaces/IProduct";
import { usePagination } from "../../../shared/hooks/usePagination";
import { ProductService } from "../../admin/products/service";
import { handleError } from "../../../shared/functions/handleError";
import ProductCard from "../shop/components/ProductCard";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

export default function Promo() {
  const [promoProducts, setPromoProducts] = useState<IProduct[]>([]);
  const { pagination } = usePagination({
    perPage: 0,
    paginationKeys: {
      hasDiscount: true
    }
  })

  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    ProductService.getAllProducts(pagination).then((response) => {
      setPromoProducts(response?.data);
    }).catch(err => handleError(err)).finally(() => {
      setIsLoading(false);
    })
  }, [pagination])

  return <div>
    {!isLoading ? (
      !!promoProducts?.length ? (
        <div className="row">
          {promoProducts?.map((promoProduct) => {
            return (
              <div className={"col-6 col-lg-4 my-2 ps-md-0"} key={promoProduct?.id}>
                <ProductCard product={promoProduct} />
              </div>
            )
          })}
        </div>
      ) : (<div> {t("general.noResults")} </div>))
      : <div className="loading-screen">
        <Spinner />
        <h4 className="ms-2"> Loading... </h4>
      </div>
    }
  </div>;
}
