import { IFaq } from "../../../shared/interfaces/IFaq";
import { IPagination } from "../../../shared/interfaces/IPagination";
import { requests } from "../../../utils/api/api.service";
import { ADMIN_FAQ_URL } from "../../../utils/api/endpoints";

export const FAQService = {
  getAllFaq: (
    pagination: IPagination
  ): Promise<{ data: IFaq[]; count: number; perPage: number }> =>
    requests.get(ADMIN_FAQ_URL, pagination),

  getFaqById: (faqId: number): Promise<IFaq> =>
    requests.get(`${ADMIN_FAQ_URL}/${faqId}`),

  updateFaq: (payload: IFaq, faqId: number) =>
    requests.put(`${ADMIN_FAQ_URL}/${faqId}`, payload),

  deleteFaq: (faqId: number) => requests.delete(`${ADMIN_FAQ_URL}/${faqId}`),

  createFaq: (payload: IFaq): Promise<IFaq> =>
    requests.post(ADMIN_FAQ_URL, payload),
};
