import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Fancybox from '../../../../shared/components/Fancybox';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller, FieldErrors, UseFormRegister, Control } from 'react-hook-form';
import { IBlogInput } from './CreateBlogPost';


interface IProps {
    currentBlog: IBlogInput | null,
    localFile: File | null,
    register: UseFormRegister<IBlogInput>,
    errors: FieldErrors<IBlogInput>,
    control: Control<IBlogInput, any>
}

export default function BlogForm({ currentBlog, localFile, register, errors, control }: IProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="px-2">
            <div className="border-bottom border-secondary mb-4">
                <h4> {!currentBlog?.id ? t('buttons.create') : t('buttons.update')} blog </h4>
            </div>

            <div>
                <label htmlFor="title"> {t('blog.title')} </label>
                <input type="text"
                    className="form-control rounded-0 p-3"
                    placeholder="Title"
                    {...register("title")}
                />
                {errors?.title && <p className='text-danger'> {t(`${errors.title.message}`)}</p>}
            </div>

            <div className="my-3">
                <label htmlFor="shortDesc"> {t('blog.shortDescription')} </label>
                <textarea
                    placeholder="Short description"
                    rows={6}
                    className="w-100 form-control rounded-0 p-3"
                    {...register("shortDescription")}
                />
                {errors?.shortDescription && <p className='text-danger'> {t(`${errors.shortDescription.message}`)}</p>}
            </div>


            <div>
                <div className="mb-3">
                    <label> {t('blog.photo')} </label>
                    <input
                        type="file"
                        className="form-control p-3 rounded-0"
                        // multiple={false}
                        accept="image/*"
                        {...register('file')}
                    />
                    {errors?.file && <p className='text-danger'> {t(`${errors.file.message}`)}</p>}


                    {localFile &&
                        <ImageHandler
                            className="object-fit-contain w-100 cursor-pointer h-400px px-0 py-2"
                            src={URL.createObjectURL(localFile)}
                        />
                    }
                </div>

                {currentBlog?.id &&
                    <Fancybox
                        options={{
                            Carousel: {
                                infinite: false,
                            },
                        }}>
                        <a data-fancybox="gallery" href={currentBlog?.path}>
                            <ImageHandler src={currentBlog?.path as string}
                                className={'cursor-pointer w-100 h-400px object-fit-contain'} />
                        </a>
                    </Fancybox>}
            </div>

            <div className="mt-3">
                <label> {t('blog.postContent')} </label>
                <Controller
                    control={control}
                    name="description"
                    render={({
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (<>

                        <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={onChange}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['bold', 'italic', 'underline'],
                                    ['link', 'image'],
                                    [{ 'align': [] }],
                                    ['clean']
                                ],
                            }}
                            className="mb-3"
                        />
                        {error?.message?.length && <p className='text-danger'>
                            {t(`${error.message}`)}
                        </p>}
                    </>)}
                />

            </div>

            <div className="d-flex justify-content-end mb-4">
                <Button className="bg-secondary text-uppercase border-0 rounded-0" onClick={() => navigate(-1)}>
                    {t("buttons.cancel")}
                </Button>

                <Button variant="primary" className="ms-2 text-uppercase rounded-0" type="submit">
                    {t(`buttons.${currentBlog?.id ? 'update' : 'create'}`)}
                </Button>
            </div>

        </div>

    )
}
