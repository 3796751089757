import React from 'react'
import { Button } from 'react-bootstrap';
import { IBrand } from '../../../../shared/interfaces/IBrand';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

interface IProps {
    currentBrand: IBrand | null,
    register: UseFormRegister<IBrand>,
    errors: FieldErrors<IBrand>,
}

export default function BrandForm({ currentBrand, register, errors }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="px-2 px-md-0 mb-3">
            <label htmlFor="name"> {t('general.name')} </label>
            <input
                placeholder={t("general.name")}
                className={`form-control rounded-0 p-3 ${errors.name && 'border-danger'}`}
                {...register("name")}

            />
            {errors?.name && <div className='text-danger small mb-3'> {t(`${errors.name.message}`)} </div>}

            <div className="d-flex justify-content-end mt-3">
                <Button className="bg-secondary text-uppercase border-0 rounded-0" onClick={() => navigate(-1)}>
                    {t("buttons.cancel")}
                </Button>

                <Button variant="primary" className="ms-2 text-uppercase rounded-0" type="submit">
                    {t(`buttons.${currentBrand?.id ? 'update' : 'create'}`)}
                </Button>
            </div>
        </div>
    )
}
