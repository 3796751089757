import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaRegHeart } from "react-icons/fa";
import { RiShoppingCartLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

interface IProps {
  isCart?: boolean;
}

export default function EmptyList({ isCart = true }: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className={
        "d-flex flex-column justify-content-center align-items-center min-h-50vh"
      }
    >
      {isCart ? (
        <RiShoppingCartLine className={"wh-100px"} />
      ) : (
        <FaRegHeart className={"wh-100px"} />
      )}

      <div className={"my-3 text-center"}>
        <h3> {t(`general.${isCart ? "emptyCart" : "emptyWishlist"}`)} </h3>
        <small>
          {t(
            `general.${isCart ? "emptyCartMessage" : "emptyWishlistMessage"}`
          )}
        </small>
      </div>

      <Button
        variant="primary"
        className={"p-3 rounded-0 text-uppercase mt-3"}
        onClick={() => navigate("/shop")}
      >
        {t("buttons.clickToShop")}
      </Button>
    </div>
  );
}
