import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ILogin } from "../../../shared/interfaces/ILogin";

interface IProps {
  register: UseFormRegister<ILogin>;
  errors: FieldErrors<ILogin>
}

export default function LoginForm({ register, errors }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className="card rounded-0">
      <div className="card-body">
        <div className="d-flex justify-content-center mb-3">
          <span className="text-uppercase text-primary fw-semibold text-decoration-underline cursor-pointer">
            {t("login.signIn")}
          </span>
          <span
            className="ms-5 text-muted cursor-pointer text-uppercase"
            onClick={() => navigate("/register")}
          >
            {t("login.register")}
          </span>
        </div>

        <input

          {...register("email")}
          placeholder={t("general.email")}
          className="form-control rounded-0 py-2"
        />
        {errors.email && <div className="text-danger small"> {t(`${errors?.email.message}`)} </div>}

        <div className="my-3">
          <Form.Group controlId="formBasicPassword">
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}

                {...register("password")}
                placeholder={t("general.password")}
                className="rounded-0 py-2"
              />

              <InputGroup.Text className="rounded-0">
                {!showPassword ? (
                  <VscEye
                    className="cursor-pointer"
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <VscEyeClosed
                    className="cursor-pointer"
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          {errors.password && <div className="text-danger small"> {t(`${errors?.password.message}`)} </div>}
        </div>

        <Button
          variant="primary"
          type="submit"
          className="rounded-0 text-uppercase w-100 text-white"
        >
          {t("buttons.login")}
        </Button>
      </div>
    </div>
  );
}
