import React, { useEffect, useState } from 'react'
import SharedTable from '../../../shared/components/SharedTable';
import UserFilter from './components/UserFilter';
import { useTranslation } from 'react-i18next';
import { useCachingState } from '../../../shared/hooks/useCachingState';
import { useNavigate } from 'react-router-dom';
import { usePagination } from '../../../shared/hooks/usePagination';
import { EOrderType } from '../../../shared/enums/EOrderType';
import { UsersService } from './service';
import { handleError } from '../../../shared/functions/handleError';
import { userTableHeaders } from './table/userTableHeaders';
import Pagination from '../../../shared/components/Pagination';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { ERole } from '../../../shared/enums/ERole';
import { toast } from 'react-toastify';
import { IUser } from '../../../shared/interfaces/IUser';

export default function AdminUsers() {
    const [users, setUsers] = useCachingState<IUser[]>('usersList', []);
    const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        pagination,
        count,
        setCount,
        changeFilterHandler,
        totalCount,
        setTotalCount,
    } = usePagination({
        perPage: 10,
        paginationKeys: {
            orderType: EOrderType.DESC,
        },
    });

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setSelectedUser(null);
    };

    const onDeleteClick = () => {
        if (selectedUser?.role === ERole.ADMIN) {
            onDeleteClose();
            toast.error('You are not able to delete admin');
            return;
        }

        UsersService.deleteUser(selectedUser?.id as number)
            .then((response) => {
                setIsDeleteOpen(false);
                toast.success(t('general.successfullyDeleted'));
                setUsers((prev) => prev.filter((user) => user.id !== selectedUser?.id));
            })
            .catch((err) => handleError(err));
    };

    useEffect(() => {
        UsersService.getAllUsers(pagination)
            .then((response) => {
                setUsers(response?.data);
                setCount(Math.ceil(response.count / response.perPage));
                setTotalCount(response?.count);
            })
            .catch((err) => handleError(err));
    }, [pagination, setCount, setTotalCount, setUsers]);

    return (
        <div>
            <UserFilter
                changeFilterHandler={changeFilterHandler}
                pagination={pagination}
            />

            <SharedTable
                data={users}
                headers={userTableHeaders}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className={'text-center'}>{t('general.listEmpty')}</div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'delete') {
                        setSelectedUser(data);
                        setIsDeleteOpen(true);
                    }
                    if (btnName === 'view') {
                        navigate(`/admin/users/edit/${data?.id}`);
                    }
                }}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />

            <ConfirmationModal
                handleClose={onDeleteClose}
                handleConfirm={onDeleteClick}
                title={selectedUser?.firstName || ''}
                show={isDeleteOpen}
            />
        </div>
    );

}
