import React from 'react'
import { useNavigate } from 'react-router-dom';

interface IProps {
    boxTitle: string;
    className: string;
}

export default function LabelBox({ boxTitle, className }: IProps) {
    const navigate = useNavigate();

    return (
        <div className={`img-position ${className} bg-white p-3 d-flex flex-column justify-content-center align-items-center w-50`}>
            <h5 className='text-uppercase fw-bold '> {boxTitle} </h5>
            <p className="small mb-3"> From world's top desginers </p>
            <button type="button" className="btn bg-dark text-white border-dark rounded-0 px-4 py-2 text-uppercase"
                onClick={() => navigate("/shop")}>Shop now</button>
        </div>
    )
}
