import React from 'react'
import { Form } from 'react-bootstrap'
import { ICategory } from '../../../../shared/interfaces/ICategory';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_CATEGORIES } from '../../../../utils/api/endpoints';
import { handleError } from '../../../../shared/functions/handleError';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CategoryForm from './CategoryForm';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Resolver, useForm } from 'react-hook-form';

export const schemaCategory = yup.object().shape({
    name: yup.string().required("error.error-required"),
    file: yup.mixed().required("error.error-required")
})

export default function CreateCategory() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, getValues, watch } = useForm<ICategory>({
        resolver: yupResolver(schemaCategory) as unknown as Resolver<ICategory, any>
    })

    const onSubmitCategory = (formData: ICategory) => {
        multipartFormData(formData?.file?.[0], ADMIN_CATEGORIES, formData, 'post', 'file').then((response) => {
            if (response) {
                toast.success(t('general.successfullyCreated'))
                navigate(-1);
            }
        }).catch(err => handleError(err))
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmitCategory)}
        >
            <CategoryForm
                currentCategory={getValues()}
                register={register}
                errors={errors}
                localFile={watch('file')?.[0]}
            />
        </Form>
    )
}
