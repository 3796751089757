import React, { useState } from "react";
import ImageHandler from "./ImageHandler";
import { FaRegHeart } from "react-icons/fa";
import { BsCart } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { routeList } from "../../utils/routes";
import { scrollTo } from "../functions/scrollTo";
import { ERole } from "../enums/ERole";
import { Badge, Dropdown } from "react-bootstrap";
import authSlice from "../../store/slices/auth.slice";
import { getInitialRoute } from "../functions/getInitialRoute";
import wishlistSlice from "../../store/slices/wishlist.slice";
import cartSlice from "../../store/slices/cart.slice";


export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const wishlist = useSelector((state: RootState) => state.wishlist);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const cart = useSelector((state: RootState) => state.cart);

  const dispatch = useDispatch();
  const { isAuthenticated, userData } = useSelector(
    (state: RootState) => state.auth
  );

  const userSpecificRoutes = routeList?.find((route) =>
    route.role.some((role) => role === userData?.role)
  );

  const headerRoutes = userSpecificRoutes?.routes?.filter(
    (route) => route.showInHeader
  );

  const { pathname } = useLocation();

  return (
    <div className="py-lg-3 position-fixed top-0 w-100 bg-white border-bottom border-beige z-3">
      <div className="container px-0">
        <div className="row align-items-center justify-content-center d-none d-lg-flex">
          <div className="col-md-2 px-0">
            <ImageHandler
              src="/images/optika-leka-logo2-removebg-preview.png"
              className="w-50 object-fit-cover cursor-pointer"
              onClick={() => navigate(getInitialRoute(userData?.role as ERole))}
            />
          </div>

          <div className="col-md-8 d-flex align-items-center justify-content-center px-0">
            {headerRoutes?.map((route) => {
              const isActive = route?.path === pathname;
              return (
                <div
                  key={route?.path}
                  onClick={() => {
                    navigate(route?.path);
                    scrollTo();
                  }}
                  className={`${isActive ? "text-primary" : ""} ${isAuthenticated && route?.path === "/login" ? "d-none" : ""
                    } cursor-pointer`}
                >
                  {!!route?.title?.length && (
                    <>
                      {route?.path === "/promo" &&
                        <div className="position-relative">
                          <Badge
                            className="bg-danger promo-badge-nav"
                          >
                            %
                          </Badge>
                        </div>}

                      <div className={`text-uppercase ${isActive ? 'underline-text' : 'hover-underline-animation'} ${route?.path !== "/login" && 'me-5'} fw-semibold`}>
                        {t(route?.title)}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div className="col-md-2 d-flex justify-content-end align-items-center px-0">
            {userData?.role !== ERole.ADMIN && (
              <>
                <div
                  className="position-relative cursor-pointer"
                  onClick={() => {
                    scrollTo();
                    navigate("/users/wishlist");
                  }}
                >
                  <FaRegHeart className="me-3 wh-20px cursor-pointer" />
                  {!!wishlist?.length && (
                    <Badge
                      className="bg-primary top-right-icon"
                      style={{ right: 2 }}
                    >
                      {wishlist?.length}
                    </Badge>
                  )}
                </div>
                <div
                  className="position-relative cursor-pointer"
                  onClick={() => {
                    scrollTo();
                    navigate("/users/cart");
                  }}
                >
                  <BsCart className="me-3 wh-20px cursor-pointer" />
                  {!!cart?.length && (
                    <Badge
                      className="bg-primary top-right-icon"
                      style={{ right: 2 }}
                    >
                      {cart?.length}
                    </Badge>
                  )}
                </div>
              </>
            )}

            {isAuthenticated ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="transparent"
                  className="border-0 p-0"
                  id="dropdown-basic"
                >
                  <FiUser className="wh-20px" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className="w-fill"
                    onClick={() => {
                      navigate(
                        `/myaccount?route=${userData?.role === ERole.ADMIN ? 1 : 2
                        }`
                      );
                    }}
                  >
                    {t("buttons.myAccount")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="w-fill"
                    onClick={() => {
                      localStorage.clear();
                      // localStorage.removeItem("token");
                      navigate("/login");
                      dispatch(authSlice.actions.setLogoutState());
                      dispatch(wishlistSlice.actions.setWishlist([]));
                      dispatch(cartSlice.actions.setCart([]));
                    }}
                  >
                    {t("buttons.logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row d-lg-none position-relative">
          <div className="col-4 px-0">
            <ImageHandler
              src="/images/optika-leka-logo2-removebg-preview.png"
              onClick={() => {
                navigate(getInitialRoute(userData?.role as ERole));
                scrollTo();
                setIsMenuOpened(false);
              }}
              className="ms-3 ms-lg-0 cursor-pointer object-fit-contain h-70px"
            />
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            <div
              className="position-relative cursor-pointer"
              onClick={() => {
                scrollTo();
                navigate('/users/wishlist');
              }}
            >
              <FaRegHeart className="me-3 wh-30px cursor-pointer" />
              {!!wishlist?.length && (
                <Badge
                  className="bg-primary top-right-icon "
                  style={{ right: 0 }}
                >
                  {wishlist?.length}
                </Badge>
              )}
            </div>
            <div
              className="position-relative cursor-pointer ms-2"
              onClick={() => {
                scrollTo();
                navigate('/users/cart');
              }}
            >
              <BsCart className="me-3 wh-30px cursor-pointer" />
              {!!cart?.length && (
                <Badge
                  className="bg-primary top-right-icon"
                  style={{ right: 0 }}
                >
                  {cart?.length}
                </Badge>
              )}
            </div>
          </div>
          <div className="col-2">
            <div
              className={`hamburger ${isMenuOpened ? 'toggle' : ''}`}
              onClick={() => setIsMenuOpened((prev) => !prev)}
            >
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </div>
          </div>
          {isMenuOpened && (
            <div
              className="col-12 position-fixed z-1 top-0 h-100 w-100 bg-dark d-flex flex-column align-items-center justify-content-center">
              {headerRoutes?.map((route) => {
                const isActive = route?.path === pathname;
                return (
                  <div
                    key={route?.path}
                    onClick={() => {
                      navigate(route?.path);
                      scrollTo();
                      setIsMenuOpened(false);
                    }}
                    className={`cursor-pointer mb-5 ${isActive ? 'text-white fw-bold' : 'text-white'
                      }
                                    ${isAuthenticated &&
                        route?.path === '/login'
                        ? 'd-none'
                        : ''
                      }`}
                  >

                    {!!route?.title?.length && (
                      <>
                        {route?.path === "/promo" &&
                          <div className="position-relative">
                            <Badge
                              className="bg-danger promo-badge-nav"
                            >
                              %
                            </Badge>
                          </div>}

                        <div className={`text-uppercase hover-underline-animation ${isActive && 'text-decoration'}`}>
                          {t(route?.title)}
                        </div>
                      </>
                    )}

                    {/* {!!route?.title?.length && (
                      <div className={`text-uppercase hover-underline-animation ${isActive && 'text-decoration'}`}>
                        {t(route?.title)}
                      </div>
                    )} */}
                  </div>
                );
              })}
              {isAuthenticated && <div
                onClick={() => {
                  navigate(
                    `/myaccount?route=${userData?.role === ERole?.ADMIN ? 1 : 2
                    }`
                  );
                  scrollTo();
                  setIsMenuOpened(false);
                }}
                className={`cursor-pointer mb-5 ${pathname === '/myaccount'
                  ? 'text-white text-decoration fw-bold'
                  : 'text-white'
                  }`}
              >
                <div className="text-uppercase hover-underline-animation">
                  Profil
                </div>
              </div>}

            </div>
          )}
        </div>
      </div>
    </div >
  );
}
