import React from 'react'
import { Form } from 'react-bootstrap'
import { IFaq } from '../../../../shared/interfaces/IFaq';
import FaqForm from './FaqForm';
import { FAQService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from 'react-hook-form';

export const schemaFaqInput = yup.object().shape({
    name: yup.string().required("error.error-required").min(6, "error.error-min-8"),
    description: yup.string().required("error.error-required"),
})

export default function CreateFaq() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, getValues, formState: { errors } } = useForm<IFaq>({ resolver: yupResolver(schemaFaqInput) })

    const onSubmitFaq = (formData: IFaq) => {
        FAQService.createFaq(formData as IFaq).then(response => {
            if (response) {
                toast.success(t("general.successfullyCreated"));
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmitFaq)}
        >
            <FaqForm currentFaq={getValues()} register={register} errors={errors} />
        </Form>
    )
}
