import React from 'react'
import { Form } from 'react-bootstrap';
import BrandForm from './BrandForm';
import { IBrand } from '../../../../shared/interfaces/IBrand';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schemaBrand } from './CreateBrand';

export default function CreateBrand() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, formState: { errors }, getValues } = useForm<IBrand>({
        resolver: yupResolver(schemaBrand),
        defaultValues: async () => await BrandService.getBrandById(Number(id))
    })

    const onSubmitBrand = (formState: IBrand) => {
        BrandService.updateBrand(formState as IBrand, formState?.id as number).then(response => {
            if (response) {
                toast.success(t("general.successfullyUpdated"));
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmitBrand)}
        >
            <BrandForm currentBrand={getValues()} register={register} errors={errors} />
        </Form>
    )
}
