import React from 'react'
import { informationHome } from '../../../../utils/data/informationHome'
import { TbTruckDelivery } from "react-icons/tb";
import { TfiExchangeVertical } from "react-icons/tfi";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const icons = [
    <TbTruckDelivery className='wh-30px text-primary' />,
    <TfiExchangeVertical className='wh-30px text-primary' />,
    <IoLocationOutline className='wh-30px text-primary' />,
    <FiPhone className='wh-30px text-primary' />
];

export default function InformationHome() {
    const { t } = useTranslation();


    return (
        <div className="col-12 mt-3 mt-md-5" data-aos="fade-down">
            <div className="row mb-2">
                {icons.map(icon => {
                    return <div className="col-md-3 d-none d-md-flex justify-content-center"> {icon}</div>
                })}
            </div>
            <div className="row">
                {informationHome?.map((item, index) => {
                    return <div className={`col-md-3 text-center mb-4 mb-md-0 ${item?.id !== 4 && 'border-md-end'}`} key={item?.id}>
                        <div className='d-md-none'>
                            {icons[index]}
                        </div>
                        <h5 className='text-uppercase fw-bold text-primary'> {t(item?.title)} </h5>
                        <small className='text-center text-muted'> {t(item?.desc)} </small>
                    </div>
                })}
            </div>
        </div>
    )
}
