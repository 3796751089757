import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import { Button } from 'react-bootstrap'
import BrandFilter from './components/BrandFilter'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePagination } from '../../../shared/hooks/usePagination'
import { IBrand } from '../../../shared/interfaces/IBrand'
import { BrandService } from './service'
import { handleError } from '../../../shared/functions/handleError'
import SharedTable from '../../../shared/components/SharedTable'
import { brandTableHeader } from './table/brandTableHeader'
import ConfirmationModal from '../../../shared/components/ConfirmationModal'
import Pagination from '../../../shared/components/Pagination'
import { toast } from 'react-toastify'

export default function Brands() {
    const [brands, setBrands] = useState<IBrand[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<IBrand | null>(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pagination, changeFilterHandler, count, totalCount, setCount, setTotalCount } = usePagination({
        perPage: 10,
    })

    const handleClose = () => {
        setIsDeleteOpen(false);
        setSelectedBrand(null);
    }

    const handleConfirmDelete = () => {
        BrandService.deleteBrand(selectedBrand?.id as number).then((response) => {
            if (response) {
                changeFilterHandler('updatedAt', new Date().getTime());
                setIsDeleteOpen(false);
                toast.success(t("general.successfullyDeleted"));
            }
        }).catch(err => handleError(err));
    }

    useEffect(() => {
        BrandService.getAllBrands(pagination).then((response) => {
            setBrands(response?.data);
            setCount(Math.ceil(response?.count / response?.perPage));
            setTotalCount(response?.count);
        }).catch(err => handleError(err));
    }, [pagination, setCount, setTotalCount]);

    return (
        <div>
            <div className="mb-3">
                <Breadcrumbs />
            </div>

            <div className='d-flex justify-content-between align-items-center mb-3 ms-2 ms-md-0'>
                <Button variant="primary"
                    className="text-uppercase rounded-0"
                    onClick={() => navigate("/admin/brand/create")}>
                    {t("buttons.create")}
                </Button>

                <BrandFilter changeFilterHandler={changeFilterHandler} />
            </div>

            <SharedTable
                data={brands}
                headers={brandTableHeader}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className='text-center'> {t("general.listEmpty")} </div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === "delete") {
                        setSelectedBrand(data);
                        setIsDeleteOpen(true);
                    }

                    if (btnName === 'view') {
                        navigate(`/admin/brand/edit/${data?.id}`);
                    }
                }}
            />


            <ConfirmationModal
                show={isDeleteOpen}
                handleClose={handleClose}
                handleConfirm={handleConfirmDelete}
                title={selectedBrand?.name as string}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />
        </div>
    )
}
