import { useEffect, useState } from 'react'
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import CategoriesFilter from './components/CategoriesFilter';
import SharedTable from '../../../shared/components/SharedTable';
import { ICategory } from '../../../shared/interfaces/ICategory';
import { usePagination } from '../../../shared/hooks/usePagination';
import { CategoryService } from './service';
import { handleError } from '../../../shared/functions/handleError';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { categoriesTableHeaders } from './table/categoriesTableHeaders';
import { EOrderType } from '../../../shared/enums/EOrderType';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import Pagination from '../../../shared/components/Pagination';
import { toast } from 'react-toastify';

export default function AdminCategories() {
    const [categories, setCategories] = useState<ICategory[]>([]);
    const { pagination, changeFilterHandler, count, totalCount, setCount, setTotalCount } = usePagination({
        perPage: 10,
        paginationKeys: {
            orderType: EOrderType.DESC,
        },
    });

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setSelectedCategory(null);
    }

    const handleDelete = () => {
        selectedCategory?.id && CategoryService.deleteCategory(selectedCategory?.id)
            .then(() => {
                changeFilterHandler('updatedAt', new Date().getTime());
                toast.success(t("general.successfullyDeleted"));
                setIsDeleteOpen(false);
            })
            .catch(err => handleError(err));
    }

    useEffect(() => {
        CategoryService.getAllCategories(pagination).then((response) => {
            setCategories(response?.data);
            setCount(Math.ceil(response?.count / response?.perPage));
            setTotalCount(response?.count);
        }).catch(err => handleError(err));
    }, [pagination, setCount, setTotalCount]);

    return (
        <div>
            <div className="mb-3">
                <Breadcrumbs />
            </div>

            <div className='d-flex justify-content-between align-items-center mb-3 ms-2 ms-md-0'>
                <Button variant="primary"
                    className="text-uppercase rounded-0"
                    onClick={() => navigate("/admin/category/create")}>
                    {t("buttons.create")}
                </Button>

                <CategoriesFilter changeFilterHandler={changeFilterHandler} pagination={pagination} />
            </div>

            <SharedTable
                data={categories}
                headers={categoriesTableHeaders}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className={'text-center'}>{t('general.listEmpty')}</div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'delete') {
                        setSelectedCategory(data);
                        setIsDeleteOpen(true);
                    }
                    if (btnName === 'view') {
                        navigate(`/admin/category/edit/${data?.id}`);
                    }
                }}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />

            <ConfirmationModal
                show={isDeleteOpen}
                handleClose={onDeleteClose}
                handleConfirm={handleDelete}
                title={String(selectedCategory?.name)}
            />
        </div>)
}
