import React from "react";
import { Form } from "react-bootstrap";
import LoginForm from "./components/LoginForm";
import { ILogin } from "../../shared/interfaces/ILogin";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthService } from "./service";
import { handleError } from "../../shared/functions/handleError";
import { useDispatch } from "react-redux";
import authSlice from "../../store/slices/auth.slice";
import { getInitialRoute } from "../../shared/functions/getInitialRoute";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const loginSchema = yup.object().shape({
  email: yup.string().email("error.invalidEmailAddress").required("error.error-required"),
  password: yup.string().required("error.error-required").min(8, "error.error-min-8"),
})

export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors } } = useForm<ILogin>({ resolver: yupResolver(loginSchema) })


  const handleLogin = async (formState: ILogin) => {
    await AuthService.loginHandler(formState)
      .then((response) => {
        localStorage.setItem("token", JSON.stringify(response?.token));

        dispatch(
          authSlice.actions.setLoginState({
            isAuthenticated: true,
            userData: response?.userData,
          })
        );
        navigate(getInitialRoute(response?.userData?.role));
        toast.success(t("general.welcomeBack"));
      })
      .catch((err) => {
        console.log(err)
        handleError(err);
      });
  };


  return (
    <div className="d-flex justify-content-center align-items-center min-h-50vh">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <Form onSubmit={handleSubmit(handleLogin)}>
              <LoginForm register={register} errors={errors} />
            </Form>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <small className="text-muted">
              {t("login.noAccount")}
              <span
                className="text-decoration-underline text-dark cursor-pointer ms-2"
                onClick={() => navigate("/register")}
              >
                {t("login.createAccount")}
              </span>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
