import React, { SetStateAction } from 'react'
import { IProduct } from '../../../../shared/interfaces/IProduct'
import { Button, Col, Row } from 'react-bootstrap';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { formatPrice } from '../../../../shared/functions/formatPrice';
import { IoMdClose } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cartSlice from '../../../../store/slices/cart.slice';
import { countNewPrice } from '../../../../shared/functions/countNewPrice';
import { checkoutSteps } from '../../../../utils/data/checkoutSteps';
import { scrollTo } from '../../../../shared/functions/scrollTo';


interface IProps {
    cart: IProduct[];
    setCurrentStep: React.Dispatch<SetStateAction<number>>;
}

export default function CartTable({ cart, setCurrentStep }: IProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onIncrement = (productId: number) => {
        const updatedList = cart?.map((product) => {
            if (productId === product?.id) {
                return { ...product, cartQuantity: Number(product?.cartQuantity) + 1 };
            }
            return product;
        });

        localStorage.setItem("cart", JSON.stringify(updatedList));
        dispatch(cartSlice.actions.setCart(updatedList));
    }

    const onDecrement = (productId: number) => {
        const currentProduct = cart?.find((product) => product?.id === productId);

        if (currentProduct?.cartQuantity === 1) {
            return;
        }

        const updatedList = cart?.map(product => {
            if (productId === product?.id) {
                return {
                    ...product,
                    cartQuantity: Number(product?.cartQuantity) - 1,
                };
            }
            return product;
        });

        localStorage.setItem("cart", JSON.stringify(updatedList));
        dispatch(cartSlice.actions.setCart(updatedList));
    }

    const handleRemoveFromCart = (productId: number) => {
        const updatedCart = cart.filter((item) => item.id !== productId);
        dispatch(cartSlice.actions.setCart(updatedCart));
        localStorage.setItem("cart", JSON.stringify(updatedCart));
    }


    return (
        <>
            <div className='d-none d-md-block'>
                <Row className={"border-bottom py-2 mb-3 mt-5"}>
                    <Col xs={5} lg={6}>
                        <h6 className={"text-uppercase"}>{t("navigation.products")}</h6>
                    </Col>
                    <Col xs={2} className={"px-0"}>
                        <h6 className={"text-uppercase"}>{t("general.price")}</h6>
                    </Col>
                    <Col xs={2} className={"px-0"}>
                        <h6 className={"text-uppercase"}>{t("cart.quantity")}</h6>
                    </Col>
                    <Col xs={3} lg={2} className={"px-0"}>
                        <h6 className={"text-uppercase"}>{t("cart.subtotal")}</h6>
                    </Col>
                </Row>

                {cart?.length ? (
                    cart?.map((item) => {
                        return (
                            <Row key={item?.id} className={`pb-3 mb-3 border-bottom`}>
                                {/* <Col xs={6} className={"ps-0"}>
                                    <Row>
                                        <Col md={4} className={"ps-0"}>
                                            <ImageHandler
                                                className={"product-order-img default-radius"}
                                                src={item?.productPhotos?.[0]?.path}
                                            />
                                        </Col>
                                        <Col
                                            md={4}
                                            className={"d-flex justify-content-center flex-column"}
                                        >
                                            <p className="mb-0 fw-bold">{item?.name || "-"}</p>

                                            <p className="mb-0 text-muted">
                                                {t("general.description")}: {item?.name || "-"}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col> */}
                                <Col xs={5} lg={6} className={"ps-0"}>
                                    <Row>
                                        <Col md={6} lg={4} className={"ps-0"}>
                                            <ImageHandler
                                                className={"product-order-img default-radius"}
                                                src={item?.productPhotos?.[0]?.path}
                                            />
                                        </Col>
                                        <Col
                                            md={6} lg={4}
                                            className={"d-flex justify-content-center flex-column"}
                                        >
                                            <p className="mb-0 fw-bold">{item?.name || "-"}</p>

                                            <p className="mb-0 text-muted">
                                                {t("general.description")}: {item?.name || "-"}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2} className={"ps-0 d-flex align-items-center"}>
                                    {!!item?.discount ? (
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <small className="text-muted text-decoration-line-through"> {formatPrice(item?.price as number)}</small>
                                            <p className='mb-0 text-muted'> {formatPrice(+countNewPrice(item?.price as number, item?.discount as number))}</p>
                                        </div>
                                    ) : (<p className="mb-0 text-muted"> {formatPrice(item?.price as number)}</p>)}
                                </Col>
                                <Col xs={2} className={"ps-0 d-flex align-items-center"}>
                                    <div className="d-flex align-items-center justify-content-center border border-2 p-1">
                                        <button
                                            className="cart-btn"
                                            onClick={() => onDecrement(item?.id as number)}
                                            disabled={item?.cartQuantity === 1}
                                        >
                                            -
                                        </button>
                                        <p
                                            className="mb-0 text-muted d-flex justify-content-center"
                                            style={{ width: 15 }}
                                        >
                                            {item?.cartQuantity}
                                        </p>
                                        <button
                                            className="cart-btn"
                                            disabled={item?.quantity <= Number(item?.cartQuantity)}
                                            onClick={() => onIncrement(item?.id as number)}
                                        >
                                            +
                                        </button>
                                    </div>
                                </Col>
                                <Col xs={1} className={"ps-0 d-flex align-items-center ms-md-3 ms-lg-0"}>
                                    {!!item?.discount ? (
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <p className='mb-0 fw-bold'> {formatPrice(+countNewPrice(item?.price as number, item?.discount as number) * Number(item?.cartQuantity))}</p>
                                        </div>
                                    ) : (<p className="mb-0 fw-bold"> {formatPrice((item?.price as number) * Number(item?.cartQuantity))}</p>)}
                                </Col>
                                <Col
                                    xs={1}
                                    className={
                                        "d-flex justify-content-center align-items-center cursor-pointer ms-md-4 ms-lg-0"
                                    }
                                    onClick={() => handleRemoveFromCart(item?.id as number)}
                                >
                                    <IoMdClose />
                                </Col>
                            </Row>
                        );
                    })
                ) : (
                    <div className={"ms-2"}>{t("general.listEmpty")}</div>
                )}

                {!!cart?.length && (
                    <Row>
                        <Col md={12} className={"d-flex justify-content-end mb-3 px-0"}>
                            <Button
                                variant="primary"
                                className={"p-2 text-uppercase rounded-0"}
                                onClick={() => setCurrentStep((prev) => prev + 1)}
                            >
                                {t("buttons.proceedToCheckout")}
                            </Button>
                        </Col>
                    </Row>
                )}
            </div>

            {/* MOBILE */}
            <div className="d-md-none">
                <div className='ms-3 mb-3'>
                    <h5 className="text-uppercase mb-0">
                        01 {t(checkoutSteps[0]?.name)}
                    </h5>
                    <small className="text-muted">
                        {t(checkoutSteps[0]?.description)}
                    </small>
                </div>

                {!!cart?.length ? cart?.map((item) => {
                    return (
                        <>
                            <div className="row border-bottom border-secondary pb-2 mb-5">
                                <div className="col-12">
                                    <div className={
                                        "d-flex justify-content-end cursor-pointer"
                                    }
                                        onClick={() => handleRemoveFromCart(item?.id as number)}
                                    >
                                        <IoMdClose style={{ width: 25, height: 25 }} /> </div>
                                </div>
                                <div className="col-6">
                                    <ImageHandler
                                        className={"product-order-img default-radius"}
                                        src={item?.productPhotos?.[0]?.path}
                                    />
                                </div>
                                <div className="col-6">
                                    <p className="mb-0 fw-bold">{item?.name || "-"}</p>

                                    {/* <p className="mb-0 text-muted">
                                        {t("general.description")}: {item?.name || "-"}
                                    </p> */}

                                    <p className="mb-2 text-muted">
                                        SKU: {item?.sku}
                                    </p>

                                    {!!item?.discount ? (
                                        <div className="d-flex flex-column ">
                                            <small className="text-muted text-decoration-line-through"> {formatPrice(item?.price as number)}</small>
                                            <p className='mb-0 text-muted'> {formatPrice(+countNewPrice(item?.price as number, item?.discount as number))}</p>
                                        </div>
                                    ) : (<p className="mb-0 text-muted"> {formatPrice(item?.price as number)}</p>)}
                                </div>

                                <div className="col-6 mt-2">
                                    <div className="d-flex align-items-center justify-content-center border border-2 p-1">
                                        <button
                                            className="cart-btn"
                                            onClick={() => onDecrement(item?.id as number)}
                                            disabled={item?.cartQuantity === 1}
                                        >
                                            -
                                        </button>
                                        <p
                                            className="mb-0 text-muted d-flex justify-content-center"
                                            style={{ width: 15 }}
                                        >
                                            {item?.cartQuantity}
                                        </p>
                                        <button
                                            className="cart-btn"
                                            disabled={item?.quantity <= Number(item?.cartQuantity)}
                                            onClick={() => onIncrement(item?.id as number)}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>

                                <div className="col-6 d-flex justify-content-center align-items-center">
                                    {!!item?.discount ? (
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <p className='mb-0 fw-bold'> {formatPrice(+countNewPrice(item?.price as number, item?.discount as number) * Number(item?.cartQuantity))}</p>
                                        </div>
                                    ) : (<p className="mb-0 fw-bold"> {formatPrice((item?.price as number) * Number(item?.cartQuantity))}</p>)}
                                </div>
                            </div>
                        </>
                    )
                }) : (
                    <div className={"ms-2"}>{t("general.listEmpty")}</div>
                )}

                {!!cart?.length && (
                    <Row>
                        <Col md={12} className={"d-flex justify-content-center mb-3"}>
                            <Button
                                variant="primary"
                                className={"p-2 text-uppercase rounded-0 w-100"}
                                onClick={() => {
                                    setCurrentStep((prev) => prev + 1);
                                    scrollTo();
                                }}
                            >
                                {t("buttons.proceedToCheckout")}
                            </Button>
                        </Col>
                    </Row>
                )}
            </div>

        </>
    )
}
