import { ERole } from "../../shared/enums/ERole";

export const myAccountData = [
  {
    id: 1,
    title: "navigation.dashboard",
    roles: [ERole.ADMIN],
  },
  {
    id: 2,
    title: "navigation.orders",
    roles: [ERole.USER],
  },
  {
    id: 3,
    title: "general.companyDashboard",
    roles: [ERole.ADMIN],
  },
  {
    id: 4,
    title: "general.address",
    roles: [ERole.USER],
  },
  {
    id: 5,
    title: "general.accountDetails",
    roles: [ERole.ADMIN, ERole.USER],
  },
  {
    id: 6,
    title: "general.wishlist",
    roles: [ERole.USER],
  },
  {
    id: 7,
    title: "buttons.logout",
    roles: [ERole.ADMIN, ERole.USER],
  },
];
