import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { IOrder } from '../../interfaces/IOrder';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { IUser } from '../../interfaces/IUser';
import { OrdersService } from '../../../app/admin/orders/service';
import { handleError } from '../../functions/handleError';
import { Col, Row } from 'react-bootstrap';
import SingleOrderTable from '../../../app/admin/orders/single-order/components/SingleOrderTable';
import SingleOrderDetails from '../../../app/admin/orders/single-order/components/SingleOrderDetails';

export default function SingleUserOrder() {
    const { id: orderId } = useParams();
    const [order, setOrder] = useState<IOrder | null>(null);
    const { id: userId } = useSelector((state: RootState) => state.auth.userData) as IUser;

    useEffect(() => {
        if (userId && orderId) {
            OrdersService.getSingleOrder(+orderId, userId).then((response) => {
                setOrder(response);
            }).catch(err => handleError(err));
        }
    }, [orderId, userId]);

    return (
        <>
            {order?.id && <Row>
                <Col lg={8}>
                    <SingleOrderTable order={order} />
                </Col>
                <Col lg={4} className='mt-md-5 mt-lg-1'>
                    <SingleOrderDetails order={order} />
                </Col>
            </Row>}
        </>
    )
}
