import React from 'react'
import { Form } from 'react-bootstrap'
import { multipartFormData } from '../../../../utils/api/api.service'
import { ADMIN_BLOG } from '../../../../utils/api/endpoints'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { handleError } from '../../../../shared/functions/handleError';
import BlogForm from './BlogForm';
import * as yup from 'yup';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
const MAX_FILE_SIZE = 10 * 1024 * 1024; // Example: 10 MB

const schemaBlog = yup.object().shape({
    title: yup.string().required("error.error-required"),
    shortDescription: yup.string().required("error.error-required"),
    description: yup.string().required("error.error-required"),
    file: yup
        .mixed()
        .required("error.error-required")
        .test('fileSize', 'error.error-required', value => {
            //@ts-ignore
            return value && value?.[0]?.size <= MAX_FILE_SIZE;
        }),
})

export interface IBlogInput {
    id?: number;
    title: string;
    path?: string;
    description: string;
    shortDescription?: string;
    file: File[]
}

export default function CreateBlogPost() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, getValues, handleSubmit, formState: { errors }, watch, control } = useForm<IBlogInput>({ resolver: yupResolver(schemaBlog) as unknown as Resolver<IBlogInput, any> })


    const handleSubmitBlog = (formData: IBlogInput) => {

        multipartFormData(formData?.file?.[0], ADMIN_BLOG, formData, 'post', 'file').then((response) => {
            if (response) {
                toast.success(t("general.successfullyCreated"));
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }
    return (
        <Form
            onSubmit={handleSubmit(handleSubmitBlog)}
        >
            <BlogForm
                control={control}
                currentBlog={getValues()}
                errors={errors}
                register={register}
                localFile={watch('file')?.[0]}
            />
        </Form>
    )
}
