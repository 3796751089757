import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { ICompany } from '../../../../shared/interfaces/ICompany';
import { CompanyService } from '../../../admin/company/service';
import { handleError } from '../../../../shared/functions/handleError';

export default function HomeMainImage() {
    const [companyInformation, setCompanyInformation] = useState<ICompany | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        CompanyService.getCompanyDetails().then(response => {
            setCompanyInformation(response);
        }).catch(err => handleError(err));
    }, []);

    return (
        <div style={{ backgroundImage: `url(${companyInformation?.path})` }} className='landing-background'>
            <div className='container company-desc'>
                <div className="mt-5 ms-3">
                    <h1 className='fw-bold display-3' data-aos="slide-right">  {companyInformation?.title}</h1>
                    <p className='mb-5 w-50 fs-20px bg-text' > {companyInformation?.description} </p>
                    <button type="button" className="btn btn-outline-dark border-dark rounded-0 px-4 py-2 text-uppercase border-2"
                        onClick={() => navigate("/shop")}>{t("general.shopNow")}</button>
                </div>
            </div>
        </div>
    )
}
