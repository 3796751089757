import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function GetInTouch() {
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    };

    return (
        <div>
            <Form data-netlify="true" name="contact"
            >
                <Form.Control
                    type="text"
                    name="firstName"
                    placeholder={t('general.firstName')}
                    className="form-control rounded-0 p-3"
                    onChange={handleChange}
                />

                <Form.Control
                    type="text"
                    name="lastName"
                    placeholder={t('general.lastName')}
                    className="form-control rounded-0 p-3 my-3"
                    onChange={handleChange}
                />

                <Form.Control
                    type="email"
                    name="email"
                    placeholder={t('general.email')}
                    className="form-control rounded-0 p-3 my-3"
                    onChange={handleChange}
                />

                <Form.Control
                    as="textarea"
                    rows={5}
                    className="form-control rounded-0 p-3 mb-3"
                    placeholder={t('general.yourMessage')}
                    onChange={handleChange}
                />
                <div className="d-flex justify-content-center justify-content-md-start">
                    <Button variant="primary" className="text-uppercase w-25 bg-primary rounded-0 text-white " type={'submit'}>
                        {t('buttons.submit')}
                    </Button>
                </div>

            </Form>
        </div>
    );
}
