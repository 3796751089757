import React, { useState } from "react";
import { IUser } from "../../../shared/interfaces/IUser";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { IRegisterInput } from "../../../shared/interfaces/IRegisterInput";

interface IProps {
  currentUser: IUser | null;
  isDisabled?: boolean;
  register: UseFormRegister<IRegisterInput>;
  errors: FieldErrors<IRegisterInput>
}

export default function RegisterForm({
  currentUser,
  isDisabled = false,
  register,
  errors
}: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className={`${isDisabled ? 'border-0' : 'my-5'} card rounded-0`}>
      <div className={`card-body ${isDisabled ? 'p-md-0' : ''}`}>
        {!currentUser?.id && (
          <div className="d-flex justify-content-center mb-2">
            <span
              className="text-uppercase me-5 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              {t("login.signIn")}
            </span>
            <span
              className={
                "text-uppercase cursor-pointer fw-semibold text-primary text-decoration-underline"
              }
            >
              {t("login.register")}
            </span>
          </div>
        )}

        <Form.Control
          placeholder={t("general.username")}
          disabled={isDisabled}
          {...register("profile")}
          className={`rounded-0 py-2 ${errors?.profile ? 'border-danger mb-0' : 'mb-3'}`}
        />
        {errors?.profile && <div className="text-danger mb-3 small">
          {t(`${errors.profile.message}`)}
        </div>}

        <div className="row">
          <div className="col-md-6 px-0 pe-md-2">
            <Form.Control
              placeholder={t("general.firstName")}
              disabled={isDisabled}
              {...register("firstName")}
              className={`rounded-0 py-2  ${errors?.firstName ? 'border-danger mb-0' : 'mb-3'}`}
            />
            {errors?.firstName && <div className="text-danger mb-3 small">
              {t(`${errors.firstName.message}`)}
            </div>}
          </div>

          <div className="col-md-6 px-0">
            <Form.Control
              placeholder={t("general.lastName")}
              disabled={isDisabled}
              className={`rounded-0 py-2  ${errors?.lastName ? 'border-danger mb-0' : 'mb-3'}`}
              {...register("lastName")}
            />
            {errors?.lastName && <div className="text-danger mb-3 small">
              {t(`${errors.lastName.message}`)}
            </div>}
          </div>
        </div>

        <Form.Control
          placeholder={t("general.email")}
          disabled={isDisabled}
          {...register("email")}
          className={`rounded-0 py-2 ${errors?.email ? 'border-danger mb-0' : 'mb-3'}`}
        />
        {errors?.email && <div className="text-danger mb-3 small">
          {t(`${errors.email.message}`)}
        </div>}

        {!currentUser?.id && (
          <div className="mb-3">
            <Form.Group controlId="formBasicPassword">
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder={t("general.password")}
                  disabled={isDisabled}
                  className={`rounded-0 py-2 ${errors?.password && 'border-danger'}`}
                  {...register("password")}
                />

                <InputGroup.Text className="rounded-0 ">
                  {!showPassword ? (
                    <VscEye
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                    />
                  ) : (
                    <VscEyeClosed
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                    />
                  )}
                </InputGroup.Text>
              </InputGroup>
              {errors?.password && <div className="text-danger mb-3 small">
                {t(`${errors.password.message}`)}
              </div>}
            </Form.Group>
          </div>
        )}

        <Form.Control
          placeholder={t("general.companyName")}
          disabled={isDisabled}
          {...register("companyName")}
          className={'rounded-0 py-2 mb-3'}
        />

        <Form.Control
          placeholder={t("general.country")}
          disabled={isDisabled}
          className={`rounded-0 py-2  ${errors?.email ? 'border-danger mb-0' : 'mb-3'}`}
          {...register("country")}
        />
        {errors?.country && <div className="text-danger mb-3 small">
          {t(`${errors.country.message}`)}
        </div>}

        <Form.Control
          disabled={isDisabled}
          placeholder={t("general.street")}
          {...register("streetAddress")}
          className={`rounded-0 py-2 ${errors?.email ? 'border-danger mb-0' : 'mb-3'}`}
        />
        {errors?.streetAddress && <div className="text-danger mb-3 small">
          {t(`${errors.streetAddress.message}`)}
        </div>}

        <Form.Control
          disabled={isDisabled}
          placeholder={t("general.city")}
          {...register("city")}
          className={`rounded-0 py-2  ${errors?.email ? 'border-danger mb-0' : 'mb-3'}`}
        />
        {errors?.city && <div className="text-danger mb-3 small">
          {t(`${errors.city.message}`)}
        </div>}

        <Form.Control
          disabled={isDisabled}
          placeholder={t("general.zipCode")}
          className={`rounded-0 py-2 ${errors?.email ? 'border-danger mb-0' : 'mb-3'}`}
          {...register("zipCode")}
        />
        {errors?.zipCode && <div className="text-danger mb-3 small">
          {t(`${errors.zipCode.message}`)}
        </div>}

        <Form.Control
          disabled={isDisabled}
          placeholder={t("general.phone")}
          {...register("phone")}
          className={`rounded-0 py-2 ${errors?.email ? 'border-danger mb-0' : 'mb-3'}`}
        />
        {errors?.phone && <div className="text-danger mb-3 small">
          {t(`${errors.phone.message}`)}
        </div>}


        <small className="text-muted fs-13px">{t("messages.register")}</small>

        {!currentUser?.id && (
          <Button
            variant="primary"
            className="mt-3 rounded-0 text-uppercase w-100 text-white"
            type="submit"
          >
            {t("login.register")}
          </Button>
        )}
      </div>
    </div>
  );
}
