import { ILogin } from "../../shared/interfaces/ILogin";
import { IUser } from "../../shared/interfaces/IUser";
import { requests } from "../../utils/api/api.service";
import {
  LOGIN_URL,
  PROFILE_URL,
  REGISTER_URL,
} from "../../utils/api/endpoints";

export const AuthService = {
  loginHandler: (data: ILogin) => requests.post(LOGIN_URL, data),
  me: () => requests.get(PROFILE_URL),
  registerHandler: (data: IUser) => requests.post(REGISTER_URL, data),
};
