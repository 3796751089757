import React, { SetStateAction, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import { IProduct } from '../../../shared/interfaces/IProduct';
import { ProductService } from '../../admin/products/service';
import { handleError } from '../../../shared/functions/handleError';
import ImageHandler from '../../../shared/components/ImageHandler';
import ProductFancybox from './components/ProductFancybox';
import { IPhoto } from '../../../shared/interfaces/IPhoto';
import ProductInfo from './components/ProductInfo';
import RelatedProducts from './components/RelatedProducts';
import { useTranslation } from 'react-i18next';
import { CategoryService } from '../../admin/categories/service';
import { ICategory } from '../../../shared/interfaces/ICategory';

export default function SingleProduct() {
    const { id } = useParams();
    const [product, setProduct] = useState<IProduct | null>(null);
    const [activeImageId, setActiveImageId] = useState(0);
    const currentImage = product?.productPhotos?.find((img) => img.id === activeImageId);
    const { t } = useTranslation();
    const [category, setCategory] = useState<ICategory | null>(null);

    useEffect(() => {
        product?.category?.id && CategoryService.getCategoryById(product?.category?.id as number).then((response) => {
            setCategory(response);
        }).catch(err => handleError(err));
    }, [product?.category?.id])

    useEffect(() => {
        !!id && ProductService.getProductById(+id).then(response => {
            setProduct({ ...response, cartQuantity: 1 });
            setActiveImageId(response?.productPhotos?.[0]?.id || 0);
        }).catch(err => handleError(err));
    }, [id]);
    return (
        <Row className="mb-3">
            <Col md={1} className="d-none d-md-flex flex-column">
                {product?.productPhotos?.map(photo => {
                    const isActive = photo?.id === activeImageId;
                    return (
                        <ImageHandler className={`product-img-sm cursor-pointer mb-1 ${isActive ? "" : "opacity-25"}`}
                            key={photo?.id}
                            onClick={() => setActiveImageId(photo?.id)}
                            src={photo?.path} />
                    )
                })}
            </Col>

            {currentImage?.id &&
                <Col md={5}>
                    <ProductFancybox
                        product={product as IProduct}
                        currentImage={currentImage as IPhoto}
                        activeImageId={activeImageId}
                        setActiveImageId={setActiveImageId} />
                </Col>}

            <ProductInfo
                product={product as IProduct}
                setProduct={setProduct as React.Dispatch<SetStateAction<IProduct>>} />

            {category?.id && category?.products && category?.products?.length > 1 &&
                <Col md={12} className={"mt-5"}>
                    <h2 className={"text-uppercase"}>
                        {t("general.related")}{" "}
                        <span className="fw-bold">{t("general.products")}</span>
                    </h2>
                </Col>
            }
            <RelatedProducts categoryId={product?.category?.id || 0} />
        </Row>
    )
}
