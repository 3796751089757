import React, { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ICategory } from '../../../../shared/interfaces/ICategory';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { CategoryService } from '../../../admin/categories/service';
import { handleError } from '../../../../shared/functions/handleError';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';
import { EOrderType } from '../../../../shared/enums/EOrderType';
import SelectDropdown, { IOptions } from '../../../../shared/components/SelectDropdown';
import { EProductSortingCriteria } from '../../../../shared/enums/ESortingCriteria';
import FilterDrawer from '../../../../shared/components/FilterDrawer';
import Search from '../../../../shared/components/Search';
import { IBrand } from '../../../../shared/interfaces/IBrand';
import { BrandService } from '../../../admin/brands/service';

interface IProps {
    colSize: number,
    setColSize: React.Dispatch<SetStateAction<number>>;
    changeFilterHandler: (name: string, value: string | number | boolean | null) => void;
    pagination: any;
}


const productsView = [
    { id: 1, userSize: 2, colSize: 6 },
    { id: 2, userSize: 3, colSize: 4 },
    { id: 3, userSize: 4, colSize: 3 },
];

const searchKeys = ["title", "sku", "color"];


export default function ShopFilter({ colSize, setColSize, changeFilterHandler, pagination }: IProps) {
    const { t } = useTranslation();
    const [showFilter, setShowFilter] = useState(false);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const { pagination: categoriesPagination } = usePagination({ perPage: 0 });
    const { pagination: brandsPagination } = usePagination({ perPage: 0 });

    const [brands, setBrands] = useState<IBrand[]>([]);


    useEffect(() => {
        CategoryService.getAllCategories(categoriesPagination)
            .then(response => setCategories(response?.data))
            .catch(err => handleError(err));
    }, [categoriesPagination]);

    useEffect(() => {
        BrandService.getAllBrands(brandsPagination).then(response =>
            setBrands(response?.data)
        ).catch(err => handleError(err));
    }, [brandsPagination]);

    return (
        <>
            <div className="d-flex align-items-center justify-content-between ps-0 mb-3 flex-column flex-md-row">
                <div className="d-none d-md-flex align-items-center mb-3 mb-md-0">
                    <h6 className='text-uppercase me-2 fw-bold'> {t("buttons.view")}</h6>
                    {productsView?.map((view) => {
                        return (
                            <h6
                                key={view?.id}
                                onClick={() => setColSize(view.colSize)}
                                className={`${colSize === view.colSize && "text-decoration-underline fw-bold"
                                    } cursor-pointer me-2 `}
                            >
                                {view.userSize}
                            </h6>
                        );
                    })}
                </div>

                <div className='d-flex align-items-center justify-content-end pe-3'>
                    <div className='px-3'>
                        <h6 className='text-uppercase cursor-pointer d-flex align-items-center fw-bold mb-0'
                            onClick={() => {
                                changeFilterHandler("orderType", pagination?.orderType === EOrderType.ASC ? EOrderType.DESC : EOrderType.ASC);
                            }}>
                            {/* {pagination?.orderType} */}
                            {pagination?.orderType === EOrderType.ASC ? (
                                <RiSortAsc className={"ms-1"} />
                            ) : (
                                <RiSortDesc className={"ms-1"} />
                            )}
                        </h6>
                    </div>

                    <div className='border-start border-end px-3'>
                        <SelectDropdown name="orderCriteria"
                            onChange={(event) => {
                                changeFilterHandler("orderCriteria", event?.target?.value);
                            }}
                            options={Object.values(EProductSortingCriteria).map((criteria) => ({
                                value: criteria,
                                name: t(`general.${criteria}`)
                            }))}
                            className="text-uppercase border-0 bg-transparent" />
                    </div>

                    <h6 className='text-uppercase cursor-pointer hover-underlne-animation ms-3 fw-bold mb-0'
                        onClick={() => setShowFilter(true)}>
                        {t("general.filters")}
                    </h6>
                </div>
            </div>

            <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
                <>
                    <div className="my-3">
                        <SelectDropdown
                            name={"categoryId"}
                            defaultLabel={t("general.filterByCategory")}
                            onChange={(event) => {
                                changeFilterHandler("categoryId", event?.target?.value);
                            }}
                            options={categories?.map((category) => ({
                                value: category?.id,
                                name: category?.name,
                            })) as IOptions[]}
                        />
                    </div>


                    <div className="mb-3">
                        <SelectDropdown name={'brandProductId'}
                            defaultLabel={t('general.filterByBrand')}
                            onChange={(event) => {
                                changeFilterHandler('brandProductId', event?.target?.value)
                            }}
                            options={brands?.map(brand => ({
                                value: brand?.id,
                                name: brand?.name
                            })) as IOptions[]}
                        />
                    </div>

                    <Search changeFilterHandler={changeFilterHandler} keys={searchKeys} />
                </>
            </FilterDrawer>
        </>
    )
}
