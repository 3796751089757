import { ERole } from "../../shared/enums/ERole";
import { adminRoutes } from "./roles/adminRoutes";
import { publicRoutes } from "./roles/publicRoutes";

export const routeList = [
  {
    role: [ERole.ADMIN],
    routes: adminRoutes,
  },
  {
    role: [ERole.USER],
    routes: publicRoutes,
  },
];
