import React from "react";
import { Form } from "react-bootstrap";
import RegisterForm from "./components/RegisterForm";
import { IUser } from "../../shared/interfaces/IUser";
import { AuthService } from "../login/service";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleError } from "../../shared/functions/handleError";
import { useForm } from "react-hook-form";
import { IRegisterInput } from "../../shared/interfaces/IRegisterInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


export const registerSchema = yup.object().shape({
  profile: yup.string().required("error.error-required"),
  firstName: yup.string().required("error.error-required"),
  lastName: yup.string().required("error.error-required"),
  email: yup.string().email("error.invalidEmailAddress").required("error.error-required"),
  password: yup.string().required("error.error-required"),
  // companyName: yup.string(),
  country: yup.string().required("error.error-required"),
  city: yup.string().required("error.error-required"),
  zipCode: yup.string().required("error.error-required"),
  phone: yup.string().required("error.error-required"),
  streetAddress: yup.string().required("error.error-required")
})

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors, }, getValues } = useForm<IRegisterInput>({
    resolver: yupResolver(registerSchema),
  })

  const handleRegister = (formState: IUser) => {
    // console.log(formState, 'formState');

    AuthService.registerHandler(formState)
      .then((response) => {
        toast.success(t("general.successfullyRegistered"));
        navigate("/login");
      })
      .catch((err) => handleError(err));
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-h-60vh">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <Form
              onSubmit={handleSubmit(handleRegister)}
            >
              <RegisterForm
                currentUser={getValues()}
                register={register}
                errors={errors}
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
